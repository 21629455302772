import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { orderBy, upperFirst } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import AddHandle from './addHandle';
import AddRanking from './addRanking';
import ChangePassword from './changePassword';
import DeleteAccount from './deleteAccount';
import EditUserDetails from './editDetails';
import EditGameRanking from './editRanking';
import IdentityVerification from './identityVerification';
import SecureWithMfa from './secureWithMfa';
import { UserFees } from './userFees';
import {
    AddButton,
    BaseButton,
    HollowButton,
    IconButton,
    SolidButton,
    TextLink,
} from '../../components/buttons-visuals';
import { Avatar } from '../../components/buttons-visuals';
import { Rule } from '../../components/buttons-visuals/rule';
import { ContentContainer } from '../../components/containers';
import GameCard from '../../components/gameCard';
import ImageUploader from '../../components/imageUploader';
import { ToggleSwitch } from '../../components/inputs';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import { ToolTip } from '../../components/overlays';
import ManagePaymentMethods from '../../components/payments/managePaymentMethods';
import useHashLinks from '../../hooks/hashLinks';
import { useLeague, useOrganizationTerm, useUserPermissionService } from '../../hooks/store';
import { AuthenticationService } from '../../services/authenticationService';
import { GameHandleSourceService } from '../../services/gameHandleSourceService';
import { OAuthService } from '../../services/oAuthService';
import { UserService } from '../../services/userService';
import { store } from '../../store';
import { loadProfile } from '../../store/profile/actions';

import './details.scss';

interface UserProfileDetailsProps {
    gameHandleSources?: Core.Models.GameHandleSource[];
    isMe: boolean;
    isPlatform?: boolean;
    leagueGames: Core.Models.Game[];
    profile: Core.Models.UserProfile;
    reloadData: () => Promise<void>;
    userId: string;
}

const UserProfileDetails = (props: UserProfileDetailsProps): JSX.Element => {
    const { isMe, isPlatform, leagueGames, profile, reloadData, userId } = props;

    const userPermissionService = useUserPermissionService();

    useHashLinks();

    const [availableGameHandleSources, setAvailableGameHandleSources] = useState<Core.Models.GameHandleSource[]>([]);
    const [gameHandleSources, setGameHandleSources] = useState<Core.Models.GameHandleSource[]>([]);
    const [isAddingGH, setIsAddingGH] = useState<boolean>(false);
    const [isAddingRanking, setIsAddingRanking] = useState<boolean>(false);
    const [rankingBeingEdited, setRankingBeingEdited] = useState<Core.Models.UserGameMetadata | undefined>(undefined);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isEditingGameInterests, setIsEditingGameInterests] = useState<boolean>(false);
    const [isSavingGameInterests, setIsSavingGameInterests] = useState<boolean>(false);
    const initialGameInterests = (profile.gameInterests && profile.gameInterests.games) || [];
    const [gameInterests, setGameInterests] = useState<Core.Models.Game[]>(initialGameInterests);
    const hasEditOnUserOrg = useMemo(
        () => userPermissionService.hasOrganizationAccess(Core.Models.PermissionLevel.Edit, profile.organizationId),
        [profile, userPermissionService]
    );
    const handles = useMemo(
        () =>
            [...(profile.gameHandles || [])].filter(
                (gh: Core.Models.GameHandle) => (gh.alwaysShow && isMe) || !!gh.handle
            ),
        [profile]
    );
    const canEditAnyOrg = userPermissionService.hasAnyOrganizationAccess(Core.Models.PermissionLevel.ElevatedEdit);
    const showSensitiveData = isMe || hasEditOnUserOrg || (canEditAnyOrg && !!profile.email);
    const showPreferredTimezone = isMe || hasEditOnUserOrg;
    const league = useLeague();
    const isAdmin = useMemo(() => userPermissionService.getIsAdmin(), [userPermissionService]);
    const isLeagueHostPlus = useMemo(
        () => userPermissionService.hasLeagueAccess(Core.Models.PermissionLevel.Edit, league?.id),
        [league?.id, userPermissionService]
    );
    const canEditGlobalUser = useMemo(
        () => isAdmin || (isMe && (!profile.isUnverifiedAccount || !profile.isUnder13)),
        [isAdmin, isMe, profile.isUnder13, profile.isUnverifiedAccount]
    );
    const canEditLeagueUser = useMemo(
        () => canEditGlobalUser || isLeagueHostPlus,
        [canEditGlobalUser, isLeagueHostPlus]
    );
    const timezone = useMemo(
        () => profile.preferredTimezone || league?.timezone,
        [league?.timezone, profile.preferredTimezone]
    );
    const birthdate = useMemo(
        () => moment(profile.birthdate, 'YYYY-MM-DD').format(Core.Constants.PROFILE_DATE_FORMAT).toString(),
        [profile.birthdate]
    );
    const age = useMemo(() => moment().diff(profile.birthdate, 'years'), [profile.birthdate]);
    const userGameRankings = useMemo(
        () =>
            profile.gameMetadata?.filter(
                (gm: Core.Models.UserGameMetadata) => gm.type === Core.Models.UserGameMetadataType.Ranking
            ),
        [profile.gameMetadata]
    );
    const gamesWithRankings = useMemo(
        () => leagueGames.filter((game: Core.Models.Game) => Core.Competition.hasRankings(game)),
        [leagueGames]
    );
    const gamesWithoutUserRankings = useMemo(
        () =>
            gamesWithRankings.filter((game: Core.Models.Game) =>
                profile.gameMetadata ? !profile.gameMetadata.some((gm) => gm.gameId === game.id) : true
            ),
        [gamesWithRankings, profile.gameMetadata]
    );
    const gameInterestsLastUpdated = useMemo(
        () =>
            !!profile.gameInterests?.lastUpdatedUtc &&
            moment
                .tz(profile.gameInterests.lastUpdatedUtc, timezone || moment.tz.guess())
                .format(Core.Constants.MATCH_DATE_FORMAT),
        [profile.gameInterests, timezone]
    );
    const organizationTerm = useOrganizationTerm();

    const reloadProfile = useCallback(() => {
        store.dispatch(loadProfile() as any);
    }, []);

    const uploadImage = async (imageData: string) => {
        setIsProcessing(true);
        await UserService.uploadAvatar({ id: userId, imageData });
        await reloadData();
        reloadProfile();
        setIsProcessing(false);
    };

    const redirectToLink = async (authorizeUrl: string, oAuthProviderId: string) => {
        const url = Core.Utils.generateFullAuthorizeUrl(window.location.href, oAuthProviderId, authorizeUrl, userId);
        window.location.href = url;
    };

    const onSubmit = async () => {
        setIsProcessing(true);
        setIsEditing(false);
        await reloadData();
        reloadProfile();
        setIsProcessing(false);
    };

    const unlink = async (
        handleSourceType: Core.Models.HandleSourceType,
        handleId?: string,
        oAuthProviderId?: string
    ) => {
        setIsProcessing(true);
        if (handleSourceType === Core.Models.HandleSourceType.OAuthProvider) {
            if (!oAuthProviderId) throw Error('Must include OAuth provider ID');
            await OAuthService.unlinkAccount({ oAuthProviderId, userId });
        } else {
            if (!handleId) throw Error('Must include handle ID');
            await UserService.deleteGameHandle(handleId);
        }

        await reloadData();
        reloadProfile();
        setIsProcessing(false);
    };

    const handleAdded = async (gameHandleSourceId: string, gameHandle: string) => {
        await UserService.addGameHandle({ gameHandleSourceId, gameHandle, userId });
        setIsAddingGH(false);
        await reloadData();
    };

    const handleChangeCompetitionPreferences =
        (property: 'hidePublicGameProfiles' | 'showDiscordCompetingStatus') =>
            async (value: boolean): Promise<void> => {
                try {
                    await UserService.updateCompetitionPreferences({
                        [property]: value,
                        userId,
                    });
                    toast.success('Privacy preferences updated');
                } catch (err) {
                    toast.error('Unable to update privacy preferences');
                    throw err; // handled in ToggleSwitch
                }

                reloadProfile();
            };

    const handleChangeNotificationPreferences =
        (property: 'sendDiscordReminders' | 'sendEmailReminders') =>
            async (value: boolean): Promise<void> => {
                try {
                    await UserService.updateNotificationPreferences({
                        [property]: value,
                        userId,
                    });
                    toast.success('Notification preferences updated');
                } catch (err) {
                    toast.error('Unable to update notification preferences');
                    throw err; // handled in ToggleSwitch
                }

                reloadProfile();
            };

    const onGameCardClick = (game: Core.Models.Game) => {
        if (gameInterests.filter((gi) => gi.id === game.id).length > 0) {
            setGameInterests(gameInterests.filter((gi) => gi.id !== game.id));
        } else {
            setGameInterests((gi) => [...gi, game]);
        }
    };

    const saveGameInterests = async () => {
        setIsSavingGameInterests(true);
        const gameIds = gameInterests.map((gi) => gi.id);
        try {
            await UserService.updateGameInterests({ userId, gameInterests: gameIds });
            await reloadData();
            setIsEditingGameInterests(false);
            toast.success('Successfully updated game interests');
        } catch (e) {
            const message = Core.API.getErrorMessage(e);
            toast.error(message);
        } finally {
            setIsSavingGameInterests(false);
        }
    };

    const addGameRanking = async (gameId: string, value: string) => {
        await UserService.addGameRanking({ gameId, userId, value });
        await reloadData();
        setIsAddingRanking(false);
    };

    const editGameRanking = async (userGameMetadataId: string, value: string) => {
        await UserService.editGameRanking({ userGameMetadataId, value });
        await reloadData();
        setRankingBeingEdited(undefined);
    };

    const deleteGameRanking = async (userGameMetadataId: string) => {
        setIsProcessing(true);
        try {
            await UserService.deleteGameRanking(userGameMetadataId);
            await reloadData();
            setIsProcessing(false);
        } catch (e) {
            toast.error('Unable to delete this rank.  Please try again.');
        }
    };

    const impersonateUser = useCallback(async () => {
        try {
            const jwt = await UserService.getUserJwt({ userId });
            AuthenticationService.setToken(jwt);
            window.location.reload();
        } catch (e) {
            const error = Core.API.getErrorMessage(e);
            toast.error(`Unable to impersonate user: ${error}`);
        }
    }, [userId]);

    // load all game handle sources on page load when user is logged in and viewing their own profile
    useEffect(() => {
        if (!canEditGlobalUser) return;

        (async () => {
            const sources = await GameHandleSourceService.getAll();
            setGameHandleSources(sources);
        })();
    }, [canEditGlobalUser]);

    // load available game handle sources that user has not yet assigned
    useEffect(() => {
        const gameHandles = profile.gameHandles;

        // get the IDs of 'gameHandleSources' that this user already has a handle for
        const existingHandleSources = (gameHandles || [])
            .filter((gameHandle) => !!gameHandle.handle || gameHandle.alwaysShow)
            .map((gameHandle) => gameHandle.handleSourceId!);

        // filter out the ones that the user already has a handle for
        setAvailableGameHandleSources(
            gameHandleSources.filter((ghs: Core.Models.GameHandleSource) => !existingHandleSources.includes(ghs.id))
        );
    }, [profile.gameHandles, gameHandleSources]);

    const avatarCanEdit = isMe && !profile.isUnverifiedAccount && (!league || !league.disablePfp);

    return (
        <div className="user-profile-details global-container mt4x mb4x">
            {/* Header */}
            <div className="user-profile-details__header mb4x">
                <div
                    className={classNames('user-profile-details__header__avatar', {
                        'user-profile-details__header__avatar--editable': avatarCanEdit,
                    })}
                >
                    <Avatar fallback="user" isUnder13={profile.isUnder13} src={profile.avatarUrl} size="xlarge" />
                    {avatarCanEdit && (
                        <ImageUploader
                            aspectRatio={16 / 16}
                            contentType="image/jpeg"
                            defaultBackgroundColor="#FFFFFF"
                            flush
                            icon="pen-to-square"
                            maxImageWidth={Core.Constants.IMAGES.MAX_AVATAR_IMAGE_WIDTH}
                            text="Update Image"
                            uploadImage={uploadImage}
                        />
                    )}
                    {isMe && <p className="color-gray text-xsmall mb0">Visible to anyone</p>}
                </div>
                <div className="disp-flex flex-dir-column">
                    <h1 className="heading-1 mb0">{Core.Identity.renderMemberName(profile)}</h1>
                    <h2 className="heading-3 color-gray mb0">{profile.gamerHandle}</h2>
                    {profile.organizationId && profile.organizationName && (
                        <TextLink className="heading-3 color-gray mb0" to={`/organizations/${profile.organizationId}`}>
                            {profile.organizationName}
                        </TextLink>
                    )}
                </div>
                {isAdmin && !isMe && (
                    <SolidButton as="button" color="destructive" onClick={impersonateUser}>
                        <FontAwesomeIcon icon={['fas', 'user-secret']} className="mr" />
                        Impersonate
                    </SolidButton>
                )}
            </div>

            <div
                className={classNames('user-profile-details__container', {
                    'user-profile-details__container--grid': canEditGlobalUser,
                })}
            >
                {/* Left Column  */}
                <div className="user-profile-details__container__info user-profile-details__grid__left">
                    {/* Info Inputs */}
                    {isEditing ? (
                        <EditUserDetails
                            onCancel={() => setIsEditing(false)}
                            {...{
                                canEditGlobalUser,
                                canEditLeagueUser,
                                isAdmin,
                                isLeagueHostPlus,
                                isMe,
                                onSubmit,
                                profile,
                                userId,
                            }}
                        />
                    ) : (
                        <>
                            {(showSensitiveData ||
                                showPreferredTimezone ||
                                (profile.leagueCustomFields && profile.leagueCustomFields.length > 0)) && (
                                    <>
                                        <ContentContainer
                                            className="user-profile-details__container__info__personal mb4x"
                                            shade={Core.Models.Shades.Dark40}
                                        >
                                            <div className="disp-flex align-center justify-between mb2x">
                                                <p className="heading-2 mb0">Personal information</p>
                                                {canEditLeagueUser && ( // this covers Admin, TO and Me
                                                    <IconButton
                                                        as="button"
                                                        buttonLabel="Edit personal information"
                                                        buttonSize="medium"
                                                        onClick={() => setIsEditing(true)}
                                                    >
                                                        <FontAwesomeIcon icon={['fas', 'edit']} />
                                                    </IconButton>
                                                )}
                                            </div>

                                            {showSensitiveData && (
                                                <>
                                                    <div className="mb2x">
                                                        <p className="color-gray mb0">Name</p>
                                                        <h2 className="weight-700 text-large mb0">
                                                            {Core.Identity.renderMemberName(profile)}
                                                        </h2>
                                                        {isMe && (
                                                            <p className="color-gray text-xsmall mb0">
                                                                Visible to{' '}
                                                                {!profile.isUnverifiedAccount
                                                                    ? 'anyone'
                                                                    : `your ${organizationTerm} Managers`}
                                                            </p>
                                                        )}
                                                    </div>

                                                    {!!profile.gamerHandle && (
                                                        <div className="mb2x">
                                                            <p className="color-gray mb0">Gamer tag</p>
                                                            <h2 className="weight-700 text-large mb0">
                                                                {profile.gamerHandle}
                                                            </h2>
                                                            {isMe && (
                                                                <p className="color-gray text-xsmall mb0">
                                                                    Visible to anyone
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}

                                                    {!!profile.username && (
                                                        <div className="mb2x">
                                                            <p className="color-gray mb0">Username</p>
                                                            <h2 className="weight-700 text-large mb0">
                                                                {profile.username}
                                                            </h2>
                                                            {isMe && (
                                                                <p className="color-gray text-xsmall mb0">
                                                                    Visible to your {organizationTerm} Managers
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}

                                                    <div className="mb2x">
                                                        <div className="color-gray mb0">Email</div>
                                                        <div className="weight-700 text-large mb0">{profile.email}</div>
                                                        {isMe && (
                                                            <p className="color-gray text-xsmall mb0">
                                                                Visible to your {organizationTerm} Managers
                                                            </p>
                                                        )}
                                                    </div>

                                                    {profile.isUnder13 && (
                                                        <div className="mb2x">
                                                            <div className="color-gray mb0">Parent email</div>
                                                            <div className="weight-700 text-large mb0">
                                                                {' '}
                                                                {profile.parentEmail ?? 'None'}
                                                            </div>
                                                            {isMe && (
                                                                <p className="color-gray text-xsmall mb0">
                                                                    Visible to your {organizationTerm} Managers
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}

                                                    {!!profile.birthdate && (
                                                        <div className="mb2x">
                                                            <div className="color-gray mb0">Birthdate</div>
                                                            <div className="weight-700 text-large mb0">{`${birthdate} (age ${age})`}</div>
                                                            {isMe && (
                                                                <p className="color-gray text-xsmall mb0">
                                                                    Visible only to you
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {showPreferredTimezone && (
                                                <div className="mb2x">
                                                    <div className="color-gray mb0">Time Zone</div>
                                                    <div className="weight-700 text-large mb0">{timezone}</div>
                                                </div>
                                            )}

                                            {profile.leagueCustomFields &&
                                                profile.leagueCustomFields.map(
                                                    (
                                                        customField: Core.Models.CustomRegistrationFieldSubmissionResponse,
                                                        ix: number
                                                    ) => (
                                                        <React.Fragment key={ix}>
                                                            {!!customField.value && (
                                                                <div className="mb2x">
                                                                    <div className="color-gray mb0">
                                                                        {customField.field.name}
                                                                    </div>
                                                                    <div className="weight-700 text-large mb0">
                                                                        {customField.value}
                                                                    </div>
                                                                    {isMe && (
                                                                        <p className="color-gray text-xsmall mb0">
                                                                            Visible to {organizationTerm} Managers & League
                                                                            Hosts
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                        </ContentContainer>

                                        {/* Two factor MFA */}
                                        <ContentContainer
                                            className="user-profile-details__container__info__account mb4x"
                                            shade={Core.Models.Shades.Dark40}
                                        >
                                            <h3 className="heading-2 mb">Account</h3>
                                            {canEditLeagueUser && !isPlatform && (
                                                <IdentityVerification {...{ profile, reloadData }} />
                                            )}
                                            {canEditGlobalUser && ( // also still passing this in - in the future, we may show MFA status to others
                                                <>
                                                    <SecureWithMfa {...{ canEditGlobalUser, isMe, profile, reloadData }} />
                                                    <Rule type="horizontal" shade={Core.Models.Shades.Dark20} />
                                                </>
                                            )}
                                            {isMe && <ChangePassword />}
                                            {canEditGlobalUser && (
                                                <DeleteAccount
                                                    name={Core.Identity.renderMemberName(profile) ?? ''}
                                                    userId={userId}
                                                    username={profile.username!}
                                                />
                                            )}
                                        </ContentContainer>

                                        {/* Fees */}
                                        <ContentContainer
                                            className="user-profile-details__container__info__fees mb4x"
                                            shade={Core.Models.Shades.Dark40}
                                        >
                                            {canEditLeagueUser && (
                                                <UserFees
                                                    fulfillments={
                                                        profile.fulfillments?.filter(
                                                            (f: Core.Models.PayableFulfillment) =>
                                                                f.entityType ===
                                                                Core.Models.PayableFulfillmentEntityType.League
                                                        ) || []
                                                    }
                                                    reloadData={reloadData}
                                                    userId={profile.userId}
                                                    title={"League Fees"}
                                                    message={"All users who participate in events that fall within the date range of a fee will need to pay that fee to be eligible to play."}
                                                    feeType={Core.Models.PayableEntityType.League}
                                                />
                                            )}
                                        </ContentContainer>

                                        <ContentContainer
                                            className="user-profile-details__container__info__fees mb4x"
                                            shade={Core.Models.Shades.Dark40}
                                        >
                                            {canEditLeagueUser && (
                                                <UserFees
                                                    fulfillments={
                                                        profile.fulfillments?.filter(
                                                            (f: Core.Models.PayableFulfillment) =>
                                                                f.entityType ===
                                                                Core.Models.PayableFulfillmentEntityType.TeamDesignation
                                                        ) || []
                                                    }
                                                    reloadData={reloadData}
                                                    userId={profile.userId}
                                                    title={"Team Designation Fees"}
                                                    message={"All users who participate on teams with certain designations in events that fall within the date range of a fee will need to pay that fee to be eligible for that team to join the sason."}
                                                    feeType={Core.Models.PayableEntityType.TeamDesignation}
                                                />
                                            )}
                                        </ContentContainer>

                                        {/* Payment methods */}
                                        <ContentContainer
                                            className="user-profile-details__container__info__payment mb4x"
                                            shade={Core.Models.Shades.Dark40}
                                        >
                                            {canEditGlobalUser && <ManagePaymentMethods />}
                                        </ContentContainer>
                                    </>
                                )}

                            {/* Notification preferences */}
                            {canEditGlobalUser && (
                                <>
                                    <ContentContainer
                                        className="user-profile-details__container__info__fees disp-flex flex-dir-column mb4x"
                                        shade={Core.Models.Shades.Dark40}
                                    >
                                        <h3 className="heading-2">Preferences</h3>
                                        <h3 className="heading-4">Notification</h3>
                                        {!profile.isUnder13 && (
                                            <ToggleSwitch
                                                label="Send Discord reminders"
                                                onToggleSwitch={handleChangeNotificationPreferences(
                                                    'sendDiscordReminders'
                                                )}
                                                value={profile.sendDiscordReminders}
                                            />
                                        )}
                                        <ToggleSwitch
                                            label="Send Email reminders"
                                            onToggleSwitch={handleChangeNotificationPreferences('sendEmailReminders')}
                                            value={profile.sendEmailReminders}
                                        />

                                        {!profile.isUnder13 && (
                                            <>
                                                <h3 className="heading-4">Privacy</h3>
                                                <ToggleSwitch
                                                    label="Set Discord competing status"
                                                    onToggleSwitch={handleChangeCompetitionPreferences(
                                                        'showDiscordCompetingStatus'
                                                    )}
                                                    value={profile.showDiscordCompetingStatus}
                                                />
                                                <ToggleSwitch
                                                    inverse
                                                    label="Show in-game stats"
                                                    onToggleSwitch={handleChangeCompetitionPreferences(
                                                        'hidePublicGameProfiles'
                                                    )}
                                                    value={profile.hidePublicGameProfiles}
                                                />
                                            </>
                                        )}
                                    </ContentContainer>
                                </>
                            )}
                        </>
                    )}
                </div>
                {/* End Left Column */}

                <div className="user-profile-details__grid__right">
                    {/* Right Column */}
                    {(canEditGlobalUser || handles.length > 0) && (
                        <ContentContainer className="mb4x" shade={Core.Models.Shades.Dark40}>
                            <div className="mb4x">
                                <h3 className="heading-2 mb0" id="game_handles">
                                    Game handles
                                </h3>
                                {isMe && (
                                    <p className="color-gray text-xsmall mb0">
                                        Visible to your Team & {organizationTerm} Managers
                                    </p>
                                )}
                            </div>
                            <div className="game-handles">
                                {handles.map((handle: Core.Models.GameHandle) => (
                                    <div
                                        className="game-handles__item disp-flex align-top"
                                        key={handle.handleSourceName}
                                    >
                                        <div className="game-handles__item__icon mr">
                                            <img
                                                src={
                                                    handle.oAuthProviderId === Core.Constants.OAUTH_DISCORD_PROVIDER_ID
                                                        ? 'https://cdn.leaguespot.gg/static/media/handle-sources/icon-discord.svg'
                                                        : handle.oAuthProviderId ===
                                                            Core.Constants.OAUTH_TWITCH_PROVIDER_ID
                                                            ? 'https://cdn.leaguespot.gg/static/media/handle-sources/icon-twitch.svg'
                                                            : handle.handleSourceIconSvgUrl
                                                }
                                                alt=""
                                            />
                                        </div>
                                        <div className="game-handles__item__content">
                                            <p
                                                className={classNames('mb', {
                                                    invalid:
                                                        handle.requiresRelink &&
                                                        (canEditGlobalUser || hasEditOnUserOrg),
                                                })}
                                            >
                                                {handle.handleSourceName}:
                                            </p>
                                            <div className="game-handles__item__content__controls">
                                                <div>
                                                    {' '}
                                                    {/* empty div for flex positioning button always at bottom of container*/}
                                                    {!!handle.handle && (
                                                        <ToolTip
                                                            decorationStyle="error"
                                                            trigger={<p className="weight-700">{handle.handle}</p>}
                                                            hide={
                                                                !handle.requiresRelink ||
                                                                !(canEditGlobalUser || hasEditOnUserOrg)
                                                            }
                                                        >
                                                            <p>
                                                                This user's {handle.handleSourceName} account needs to
                                                                be relinked in order to verify their in-game identity.
                                                                Only this user can relink the account from their
                                                                profile.
                                                            </p>
                                                        </ToolTip>
                                                    )}
                                                </div>
                                                {canEditGlobalUser && (
                                                    <div className="disp-flex flex-gap">
                                                        {/* (re)link button can only be used when linking is possible and the user is on their own profile */}
                                                        {!!handle.oAuthProviderId && isMe && (
                                                            <HollowButton
                                                                as="button"
                                                                size="small"
                                                                color="secondary"
                                                                disabled={isProcessing}
                                                                onClick={() =>
                                                                    redirectToLink(
                                                                        handle.authorizeUrl!,
                                                                        handle.oAuthProviderId!
                                                                    )
                                                                }
                                                            >
                                                                {!!handle.handle || handle.requiresRelink
                                                                    ? 'Relink'
                                                                    : 'Link'}
                                                            </HollowButton>
                                                        )}

                                                        {/* delete button can be used by admins or the user themselves */}
                                                        {!!handle.handle && (
                                                            <SolidButton
                                                                as="button"
                                                                aria-label="Remove game handle"
                                                                color="destructive"
                                                                disabled={isProcessing}
                                                                onClick={async () => {
                                                                    await unlink(
                                                                        handle.handleSourceType,
                                                                        handle.id,
                                                                        handle.oAuthProviderId
                                                                    );
                                                                }}
                                                                size="small"
                                                            >
                                                                Delete
                                                            </SolidButton>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {canEditGlobalUser && (
                                <AddButton
                                    as="button"
                                    buttonSize="medium"
                                    buttonStyle="solid"
                                    className="mt2x"
                                    layoutInline
                                    onClick={() => setIsAddingGH(true)}
                                >
                                    Add new handle
                                </AddButton>
                            )}
                        </ContentContainer>
                    )}

                    {(canEditGlobalUser || (!!userGameRankings?.length && userGameRankings.length > 0)) &&
                        gamesWithRankings.length > 0 &&
                        profile.organizationId && (
                            <ContentContainer className="mb4x" shade={Core.Models.Shades.Dark40}>
                                <div className="mb4x">
                                    <h3 className="heading-2 mb0">Game Ranks</h3>
                                    {isMe && <p className="color-gray text-xsmall mb0">Visible to anyone</p>}
                                </div>
                                <ul className="user-profile-details__rankings mb2x">
                                    {!!profile.gameMetadata &&
                                        orderBy(
                                            profile.gameMetadata,
                                            (gm: Core.Models.UserGameMetadata) => gm.game.name
                                        )
                                            .filter(
                                                (gm: Core.Models.UserGameMetadata) =>
                                                    gm.type === Core.Models.UserGameMetadataType.Ranking
                                            )
                                            .map((ranking: Core.Models.UserGameMetadata) => {
                                                const game = gamesWithRankings.find(
                                                    (g: Core.Models.Game) => g.id === ranking.gameId
                                                );
                                                const isValid = Core.Competition.isValidRanking(ranking.value, game);
                                                return (
                                                    <li>
                                                        <ContentContainer
                                                            className="user-profile-details__rankings__item"
                                                            key={ranking.game.id}
                                                            shade={Core.Models.Shades.Dark20}
                                                        >
                                                            <div className="disp-flex justify-between mb2x">
                                                                <div
                                                                    className={classNames({
                                                                        invalid:
                                                                            !isValid &&
                                                                            (canEditGlobalUser || hasEditOnUserOrg),
                                                                    })}
                                                                >
                                                                    {ranking.game.name}:
                                                                </div>
                                                                <ToolTip
                                                                    decorationStyle="error"
                                                                    hide={
                                                                        isValid ||
                                                                        !(canEditGlobalUser || hasEditOnUserOrg)
                                                                    }
                                                                    trigger={
                                                                        <span className="weight-700">
                                                                            {ranking.value}
                                                                        </span>
                                                                    }
                                                                >
                                                                    <p>
                                                                        This user's {ranking.game.name}{' '}
                                                                        {ranking.game.rankingTerm} needs to be updated
                                                                        because <strong>{ranking.value}</strong> is no
                                                                        longer valid.
                                                                    </p>
                                                                </ToolTip>
                                                            </div>

                                                            {canEditGlobalUser && (
                                                                <div className="disp-flex justify-between">
                                                                    <HollowButton
                                                                        as="button"
                                                                        color="secondary"
                                                                        onClick={() => setRankingBeingEdited(ranking)}
                                                                        size="small"
                                                                    >
                                                                        Edit
                                                                    </HollowButton>
                                                                    <SolidButton
                                                                        as="button"
                                                                        color="destructive"
                                                                        onClick={() => deleteGameRanking(ranking.id)}
                                                                        pending={isProcessing}
                                                                        size="small"
                                                                    >
                                                                        Delete
                                                                    </SolidButton>
                                                                </div>
                                                            )}
                                                        </ContentContainer>
                                                    </li>
                                                );
                                            })}
                                </ul>

                                {canEditGlobalUser && gamesWithoutUserRankings.length > 0 && (
                                    <AddButton
                                        as="button"
                                        buttonSize="medium"
                                        buttonStyle="solid"
                                        layoutInline
                                        onClick={() => setIsAddingRanking(true)}
                                    >
                                        Add new rank
                                    </AddButton>
                                )}
                            </ContentContainer>
                        )}

                    {canEditGlobalUser && profile.organizationId && !isPlatform && leagueGames.length > 0 && (
                        <ContentContainer className="game-interests mb4x" shade={Core.Models.Shades.Dark40}>
                            <div className="game-interests__header mb4x">
                                <div>
                                    <h3 className="heading-2 mb0">Game interests within this league</h3>
                                    {isMe && <p className="color-gray text-xsmall mb0">Visible only to you</p>}
                                </div>
                                <div className="align-center disp-flex">
                                    {isSavingGameInterests ? (
                                        <Loading />
                                    ) : isEditingGameInterests ? (
                                        <>
                                            <HollowButton
                                                as="button"
                                                color="secondary"
                                                onClick={() => {
                                                    setIsEditingGameInterests(false);
                                                    setGameInterests(initialGameInterests);
                                                }}
                                                size="small"
                                            >
                                                Cancel
                                            </HollowButton>
                                            <SolidButton
                                                as="button"
                                                className="ml"
                                                onClick={saveGameInterests}
                                                size="small"
                                            >
                                                Save
                                            </SolidButton>
                                        </>
                                    ) : (
                                        <div className="disp-flex flex-dir-column align-end">
                                            <HollowButton
                                                as="button"
                                                color="secondary"
                                                onClick={() => setIsEditingGameInterests(true)}
                                                size="small"
                                            >
                                                Update
                                            </HollowButton>
                                            <p className="mb0 text-small">
                                                (last updated: {gameInterestsLastUpdated || 'Never'})
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <ul className="game-interests__grid">
                                {orderBy(leagueGames, (lg: Core.Models.Game) => lg.name).map(
                                    (game: Core.Models.Game) => (
                                        <li key={game.id}>
                                            <BaseButton
                                                as="button"
                                                className={classNames('game-interests__grid__item', {
                                                    'game-interests__grid__item--disabled': !isEditingGameInterests,
                                                    'game-interests__grid__item--editing': isEditingGameInterests,
                                                    'game-interests__grid__item--selected':
                                                        gameInterests.filter((g: Core.Models.Game) => g.id === game.id)
                                                            .length > 0,
                                                })}
                                                disabled={!isEditingGameInterests}
                                                onClick={
                                                    isEditingGameInterests ? () => onGameCardClick(game) : undefined
                                                }
                                            >
                                                <GameCard
                                                    gameName={game.name}
                                                    imageUrl={game.cardUrl}
                                                    isSelecting={isEditingGameInterests}
                                                    isSelected={
                                                        gameInterests.filter((g) => g.id === game.id).length > 0
                                                    }
                                                    size="small"
                                                />
                                            </BaseButton>
                                        </li>
                                    )
                                )}
                            </ul>
                        </ContentContainer>
                    )}
                    {/* End Right Column */}
                </div>
            </div>

            {isAddingGH && availableGameHandleSources.length > 0 && (
                <Modal onClose={() => setIsAddingGH(false)} title="Add New Handle">
                    <AddHandle gameHandleSources={availableGameHandleSources} onSubmit={handleAdded} />
                </Modal>
            )}
            {isAddingRanking && (
                <Modal onClose={() => setIsAddingRanking(false)} title="Add New Rank">
                    <AddRanking games={gamesWithoutUserRankings} onSubmit={addGameRanking} />
                </Modal>
            )}
            {!!rankingBeingEdited && (
                <Modal
                    onClose={() => setRankingBeingEdited(undefined)}
                    title={`Edit ${rankingBeingEdited.game.name} ${upperFirst(rankingBeingEdited.game.rankingTerm)}`}
                >
                    <EditGameRanking userGameRanking={rankingBeingEdited} onSubmit={editGameRanking} />
                </Modal>
            )}
        </div>
    );
};

export default UserProfileDetails;
