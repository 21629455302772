import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { HollowButton, SolidButton } from '../../../components/buttons-visuals';
import FulfillPayableModal from '../../../components/payments/fulfillmentsList/fulfillPayableModal';
import withLoading, { WithLoadingProps } from '../../../components/withLoading';
import PaymentGatewayService from '../../../services/paymentGatewayService';

interface UserFeesProps extends WithLoadingProps {
    fulfillments: Core.Models.PayableFulfillment[];
    reloadData: (showLoading: boolean) => Promise<void>;
    userId: string;
    title: string;
    message: string;
    feeType: Core.Models.PayableEntityType;
}

const UserFees = ({
    fulfillments: inputFulfillments,
    reloadData,
    setError,
    setIsLoading,
    userId,
    title,
    message,
    feeType
}: UserFeesProps): JSX.Element => {
    const [fulfillments, setFulfillments] = useState<Core.Models.PayableFulfillment[]>(inputFulfillments);
    const [isFulfillingPayableId, setIsFulfillingPayableId] = useState<string | undefined>(undefined);
    const [payables, setPayables] = useState<Core.Models.Payable[]>([]);

    const load = useCallback(async () => {
        setIsLoading(true);

        try {
            const payablesData = await PaymentGatewayService.getPayables(feeType);
            setPayables(payablesData);
        } catch (e) {
            toast.error('There was an issue retrieving fees');
        } finally {
            setIsLoading(false);
        }
    }, [setError, setIsLoading]);

    useEffect(() => {
        (async () => {
            await load();
        })();
    }, []);

    return (
        <div id="league-fees">
            <h3 className="heading-2">{title}</h3>
            <p>
                {message}
            </p>

            <ul>
                {payables.map((payable: Core.Models.Payable) => (
                    <li key={payable.id} className="league-fees__item disp-flex align-start justify-between">
                        <div>
                            <div className="weight-700">{payable.name}</div>
                            <div className="heading-2">
                                <span style={{ textDecoration: Core.Utils.isDiscounted(payable) ? 'line-through' : 'none' }}>{Core.Currency.convertNumberToCurrency(payable.fee)}</span>
                                {Core.Utils.isDiscounted(payable) && <span style={{ marginLeft: 5 }}>{Core.Currency.convertNumberToCurrency(payable.discountFee!)}</span>}
                            </div>
                            <div>
                                {Core.Utils.isDiscounted(payable) && <p>
                                    *Discounted until {moment(payable.discountEndUtc).format(Core.Constants.TIMESTAMP_FORMAT)}
                                </p>}

                                <FontAwesomeIcon className="mr" icon={['fas', 'calendar']} size="1x" />
                                {moment(payable.startDate).format(`MMM DD, 'YY`)}
                                <FontAwesomeIcon className="ml mr" icon={['fas', 'arrow-right']} size="1x" />
                                {moment(payable.endDate).format(`MMM DD, 'YY`)}
                            </div>
                        </div>
                        <div>
                            {fulfillments
                                .filter((f: Core.Models.PayableFulfillment) => f.payableId === payable.id)
                                .filter((f: Core.Models.PayableFulfillment) => f.userId == userId)
                                .filter((f: Core.Models.PayableFulfillment) => f.complete)
                                .filter((f: Core.Models.PayableFulfillment) => !f.refunded).length > 0 ? (
                                <div>
                                    <HollowButton as="button" color="secondary" disabled size="small">
                                        <FontAwesomeIcon
                                            className="mr color-success"
                                            icon={['fas', 'check']}
                                            size="1x"
                                        />
                                        Paid
                                    </HollowButton>
                                </div>
                            ) : (
                                <SolidButton
                                    as="button"
                                    onClick={() => setIsFulfillingPayableId(payable.id)}
                                    size="small"
                                >
                                    Pay fee
                                </SolidButton>
                            )}
                        </div>
                    </li>
                ))}
            </ul>

            {!!isFulfillingPayableId && (
                <FulfillPayableModal
                    onCompleted={() => setIsFulfillingPayableId(undefined)}
                    payableEntityType={feeType}
                    payableId={isFulfillingPayableId}
                    {...{
                        fulfillments,
                        reloadData,
                        setFulfillments,
                        userId,
                    }}
                />
            )}
        </div>
    );
};

export default withLoading(UserFees);
