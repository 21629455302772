import React, { useMemo, useState } from 'react';

import LeagueDetails from './details';
import FeaturedAffiliates from './featuredAffiliates';
import LeagueOrganizations from './organizations';
import LeagueSeasons from './seasons';
import SocialFeed from '../../components/socialFeed';
import StreamsPlayer from '../../components/twitch/streamsPlayer';
import { useLeagueConfiguration, useProfile } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import OrganizationTeams from '../organization/teams';

import './index.scss';

const LeaguePage = (): JSX.Element => {
    const [selectedGameId, setSelectedGameId] = useState<string | undefined>();
    const league = useLeagueConfiguration();
    const profile = useProfile();
    const clubOrganizationId = useMemo(() => league?.clubOrganization?.id, [league]);
    const canViewFeed = useMemo(
        () => !!profile && !profile.isUnverifiedAccount && !!league?.league.enableSocialFeatures,
        [league, profile]
    );

    return (
        <div className="page league-page">
            <LeagueDetails selectGame={(gameId: string) => setSelectedGameId(gameId)} />

            {canViewFeed && <SocialFeed />}

            <StreamsPlayer loadStreams={LeagueService.getTwitchLeagueStreams} />

            <FeaturedAffiliates />

            <LeagueSeasons requestedSelectedGameId={selectedGameId} />

            {!!clubOrganizationId ? (
                <div className="league-page__teams">
                    <OrganizationTeams organizationId={clubOrganizationId} hideTeamDetails />
                </div>
            ) : (
                <LeagueOrganizations />
            )}
        </div>
    );
};

export default LeaguePage;
