import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import UserProfileDetails from './details';
import UserMatchList from './matchList';
import UserProfileTeams from './teams';
import { ContentContainer } from '../../components/containers';
import FulfillmentsList from '../../components/payments/fulfillmentsList';
import withLoading, { WithLoadingProps } from '../../components/withLoading';
import { useHasAnyLeagueMembershipRole, useIsPlatform, useUserPermissionService } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { UserService } from '../../services/userService';
import ForbiddenPage from '../forbidden';

import './index.scss';

interface UserProfilePageRouteProps {
    userId: string;
}

interface UserProfilePageProps extends WithLoadingProps, RouteComponentProps<UserProfilePageRouteProps> {}

const UserProfilePage = (props: UserProfilePageProps): JSX.Element => {
    const {
        match: {
            params: { userId },
        },
        setError,
        setIsLoading,
    } = props;

    const isPlatform = useIsPlatform();
    const userPermissionService = useUserPermissionService();
    const isLeagueMember = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.ListSpecific);
    const isLeagueHostPlus = useHasAnyLeagueMembershipRole(Core.Models.PermissionLevel.Edit);

    const [leagueGames, setLeagueGames] = useState<Core.Models.Game[]>([]);
    const [targetUserProfile, setTargetUserProfile] = useState<Core.Models.UserProfile | undefined>();

    const isMe = useMemo(
        () => !!userPermissionService.getUserId() && userPermissionService.getUserId() === targetUserProfile?.userId,
        [targetUserProfile, userPermissionService]
    );
    const canEditFulfillments = useMemo(() => isLeagueHostPlus || isMe, [isLeagueHostPlus, isMe]);
    const canViewUserProfile = useMemo(
        () => isMe || !(targetUserProfile?.isUnder13 ?? true) || isLeagueMember,
        [isLeagueMember, isMe, targetUserProfile?.isUnder13]
    );

    const loadData = useCallback(async () => {
        try {
            setIsLoading(true);

            const profileData = isPlatform
                ? await UserService.getPlatformProfile(userId)
                : await UserService.getProfile(userId);
            setTargetUserProfile(profileData);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }, [isPlatform, setError, setIsLoading, userId]);

    useEffect(() => {
        (async () => {
            await loadData();

            try {
                const leagueGamesData = isPlatform ? [] : await LeagueService.getLeagueApprovedGames();
                setLeagueGames(leagueGamesData);
            } catch {
                toast.error('Failed to load games');
            }
        })();
    }, [isPlatform, loadData, userId]);

    if (!targetUserProfile) return <></>;
    if (!canViewUserProfile)
        return (
            <ForbiddenPage
                message="This user's information is protected and you are not logged in as a member of this league."
                title="You can't view this account"
            />
        );

    return (
        <div className="user-profile">
            <UserProfileDetails
                {...{
                    isMe,
                    isPlatform,
                    leagueGames,
                    profile: targetUserProfile,
                    reloadData: loadData,
                    userId,
                }}
            />
            {!isPlatform && (
                <>
                    <UserProfileTeams id={userId} />
                    <UserMatchList
                        headerText="Event History"
                        type={Core.Models.UserMatchListType.History}
                        userId={userId}
                    />
                    {canEditFulfillments && (
                        <ContentContainer className="global-container mb4x" shade={Core.Models.Shades.Dark40}>
                            <FulfillmentsList
                                fulfillments={targetUserProfile.fulfillments || []}
                                reloadData={loadData}
                            />
                        </ContentContainer>
                    )}
                </>
            )}
        </div>
    );
};

export default withLoading(UserProfilePage, {
    errorDisplayPageProps: { fullPage: true },
    loadingProps: { blockItem: true },
    showNotFoundFor404: true,
});
