import { Dictionary } from 'lodash';

export interface ApiResponse {
	errorMessage?: string;
	statusCode: number;
	success: boolean;
}

export enum DropdownSelection {
	Unselected = -1,
}

export interface DecodedToken {
	email: string;
	exp: number;
	expires_in: number;
	family_name: string;
	given_name: string;
	is_admin: boolean;
	league_id?: string;
	nbf: number;
	sub: string;
	unique_name: string;
}

export interface ApiObjectResponse<TResult> extends ApiResponse {
	result?: TResult;
}

export interface ReloadDataProps {
	reloadData: (showLoading: boolean, reload: boolean) => Promise<void>;
}

export interface GetPermissionsProps {
	getPermissions: () => Promise<void>;
}

export interface GetLeagueProps {
	getLeague: (showLoading: boolean) => Promise<void>;
}

export interface LoginUserCommand {
	extendExpiration?: boolean;
	otpCode?: string;
	password: string;
	username: string;
}

export interface LoginPlatformUserCommand extends LoginUserCommand {
	deviceMetadata?: {
		expectsNotification: boolean;
		name: string;
		notificationToken: string | null;
	}
}

export interface RefreshTokenCommand {
	expirationHours: number;
}

export interface LoginResponse {
	requiresMfa: boolean;
	token?: string;
}

export interface AvailableOrganization extends NamedObject {
	streetAddress: string;
	city: string;
	state: string;
	postalCode: string;
	phoneNumber: string;
}

export interface AssignedRoles {
	leagueRoles: AssignedRole[];
	organizationRoles: AssignedRole[];
	teamRoles: AssignedRole[];
}

export interface AssignedRole {
	entityId: string;
	leaguePermissions: PermissionLevel;
	organizationPermissions: PermissionLevel;
	seasonIds: string[];
	teamPermissions: PermissionLevel;
}

export interface Permissions {
	leagues: { [leagueId: string]: AssignedRole };
	organizations: { [organizationId: string]: AssignedRole };
	teams: { [teamId: string]: AssignedRole };
}

export enum PermissionLevel {
	// cross-cutting base permissions
	Anonymous = 0,
	ListAll = 1,

	// entity-specific, role-based permissions
	ListSpecific = 2,
	Contribute = 3,
	Edit = 4,
	ElevatedEdit = 5,
}

export interface BaseObject {
	id: string;
}

export interface NamedObject extends BaseObject {
	name: string;
}

export interface Sortable {
	sortOrder: number;
}

export interface RegistrationResponse {
	messages: string[];
	redirectUrl?: string;
	sentVerificationEmail: boolean;
	succeeded: boolean;
	username?: string;
}

export interface CreateTeamCommand {
	organizationId: string;
	gameId: string;
	name: string;
	teamRoleId?: string;
}

export interface EditTeamCommand extends NamedObject {
	designationId: string | null; // Nullable for removal. 
}

interface LeagueGrid {
	gridItems: LeagueGridItem[];
	rotatorItems: LeagueGridItem[];
}

export interface LeagueGridItem {
	titleId: string;
	url: string;
}

export enum LeaguePlan {
	Lite = 100,
	Enterprise = 300,
	Legacy = 400,
}

export interface League extends NamedObject {
	allowU13: boolean;
	alternateSeasonName?: string;
	bannerUrl?: string;
	bypassEmailVerificationRestrictions: boolean;
	colors?: ThemeColors;
	customRegistrationFieldsEnabled: boolean;
	description?: string;
	disableDiscordCompetingStatus: boolean;
	disableDiscordNotifications: boolean;
	disableDms: boolean;
	disableInvites: boolean;
	disablePfp: boolean;
	discordGuildId?: string;
	discordUrl?: string;
	edition: LeagueEdition;
	email?: string;
	enableChat: boolean;
	enablePaymentFeatures: boolean;
	enableSeasonAgeRestrictions: boolean;
	enableSocialFeatures: boolean;
	faviconUrl?: string;
	featureGroup: FeatureGroup;
	grid?: LeagueGrid;
	headerLogoUrl?: string;
	hideFromSearch: boolean;
	identityVerificationInstructions?: string;
	isBilling: boolean;
	landingPageFilePath?: string;
	legalEntity: string;
	legalUrl: string;
	logoColors?: string[];
	logoUrl?: string;
	membersCanCreateTeams: boolean;
	organizationTerm: string;
	parentLeagueId?: string;
	phoneNumber?: string;
	plan: LeaguePlan;
	playersCanLeaveTeams: boolean;
	requireIdentityVerification: boolean;
	requireUsersToLinkDiscord: boolean;
	restrictChatUsers: boolean;
	restrictRegistrationToWhitelistedDomains: boolean;
	timezone?: string;
	truncatePlayerNames: boolean;
	u13ConsentTerms: string;
	websiteUrl?: string;
}

export enum LeagueEdition {
	Association = 0,
	Club = 1,
}

export interface LeagueAutoGenerationOptions {
	membersPerOrganization: number;
	organizations: number;
}

export enum FeatureGroup {
	Generic = 0,
	NACE = 2,
	HerGalaxy = 4,
}

export enum CompetitionStyle {
	HeadToHead = 0,
	Leaderboard = 1,
}

export type CompetitionStyleSelection = DropdownSelection | CompetitionStyle;
export const CompetitionStyleSelection = { ...DropdownSelection, ...CompetitionStyle };

export interface BasicLeague extends NamedObject {
	domain: string;
	logoUrl?: string;
	timezone: string;
}

export interface LeagueDesignation extends NamedObject {
	color: string;
	iconName?: string;
}

export interface LeagueLocation extends NamedObject {
	deleted: boolean;
	description?: string;
	stations: LeagueLocationStation[];
}

export interface LeagueLocationStation extends NamedObject {
	deleted: boolean;
	description?: string;
	systemName?: string;
}

export interface Game extends NamedObject {
	cardUrl?: string;
	competitionStyle: CompetitionStyle;
	esrbRating: EsrbRating;
	eventType: EventType;
	featureSet: FeatureSet;
	gameHandleSource?: GameHandleSource;
	gameHandleSourceId?: string;
	heroUrl?: string;
	iconUrl?: string;
	maximumLobbySize?: number;
	maximumSeats: number;
	minimumSeats: number;
	rankings: { [key: string]: number };
	rankingTerm: string;
	rankingType: RankingType;
	resultFileExtensions?: string[];
	scoringType: ScoringType;
	supportsTennisStyle: boolean;
	supportsTies: boolean;
}

export enum EsrbRating {
	None = 0,
	AO = 1,
	E = 2,
	E10Plus = 3,
	M = 4,
	RP = 5,
	RP17Plus = 6,
	T = 7,
}

export enum RankingType {
	Dictionary = 0,
	Numeric = 1,
}

export interface CreateGameRequest {
	cardImageData: string;
	competitionStyle: CompetitionStyleSelection;
	disablePublic: boolean;
	esrbRating: EsrbRating;
	gameHandleSourceId?: string;
	heroImageData: string;
	iconImageData: string;
	maximumLobbySize?: number;
	maximumSeats: number;
	minimumSeats: number;
	name: string;
	scoringType: ScoringTypeSelection;
	supportsTennisStyle: boolean;
	supportsTies: boolean;
}

export interface EditGameRequest {
	disablePublic: boolean;
	eventType: EventType;
	gameHandleSourceId?: string;
	id: string;
	maximumLobbySize?: number;
	maximumSeats: number;
	minimumSeats: number;
	name: string;
	rankingTerm: string;
	rankingType: RankingType;
	resultFileExtensions?: string;
	scoringType: ScoringTypeSelection;
	supportsTennisStyle: boolean;
	supportsTies: boolean;
}

export enum EventType {
	Competition = 0,
	Class = 1,
}

export enum FeatureSet {
	Generic = 0,
	Hearthstone = 1,
	LeagueOfLegends = 2,
	Fortnite = 3,
	Chess = 4,
	LeagueOfLegendsAram = 5,
	ApexLegends = 6,
	Valorant = 7,
}

export enum ScoringType {
	Discrete = 0,
	Categorical = 1,
	Binary = 2,
}

export type ScoringTypeSelection = DropdownSelection | ScoringType;
export const ScoringTypeSelection = { ...DropdownSelection, ...ScoringType };

export interface GameHandleSource extends NamedObject {
	iconSvgUrl?: string;
	oAuthProviderId?: string;
}

export enum CustomRegistrationFieldType {
	Textbox = 0,
	Checkbox = 1,
	Select = 2,
}

export enum CustomRegistrationFieldEntityType {
	User = 0,
}

export class CustomRegistrationField implements NamedObject {
	constructor(init?: Partial<CustomRegistrationField>) {
		this.id = '';
		this.required = false;
		this.fieldType = CustomRegistrationFieldType.Textbox;
		this.entityType = CustomRegistrationFieldEntityType.User;
		this.name = '';
		this.options = [];

		Object.assign(this, init);
	}

	id: string;
	required: boolean;
	fieldType: CustomRegistrationFieldType;
	entityType: CustomRegistrationFieldEntityType;
	name: string;

	// checkbox fields
	details?: string;
	label?: string;

	// textbox fields
	minLength?: number;
	maxLength?: number;
	placeholderText?: string;
	isTextArea?: boolean;

	// select fields
	options?: string[];
}

export class CustomRegistrationFieldSubmissionResponse {
	constructor(field: CustomRegistrationField = new CustomRegistrationField(), value: string = '') {
		this.field = field;
		this.value = value;
	}

	field: CustomRegistrationField;
	value: string;
}

export interface CustomRegistrationFieldSubmissionRequest {
	id: string;
	name: string;
	value: string;
}

export interface SeasonCategory extends NamedObject, Sortable {
	seasons: Season[];
	isVisible: boolean;
}

export interface SeasonCategoryBasic {
	id: string;
	seasonIds: string[];
}

export interface Season extends NamedObject, Sortable {
	ageMax?: number;
	ageMin?: number;
	allowFreeAgents: boolean;
	allowedTeamDesignations: LeagueDesignation[];
	currentStageId?: string;
	currentState: CompetitionState;
	description?: string;
	designationId?: string;
	enablePublicRegistration: boolean;
	enforceMinimumRoster: boolean;
	freeAgents?: SeasonFreeAgent[];
	game: Game;
	isVisible: boolean;
	leagueId: string;
	maxParticipants?: number;
	metadata?: string;
	participants: Participant[];
	payable?: Payable;
	payableId?: string;
	requireGameRanks: boolean;
	requireHandleForCheckin: boolean;
	seasonOperators?: SeasonOperator[];
	seasonTerms?: string;
	skillLevel: SkillLevel;
	stages: Stage[];
	startDate?: string;
	tennisStyle: boolean;
	tennisStyleSets?: number;
	timingDescription?: string;
	titleConfiguration?: ChessConfiguration;
}

export interface JoinableSeason extends NamedObject { }

export interface ChessConfiguration {
	generateChessDotComLinks: boolean;
}

export interface SeasonOperator {
	avatarUrl: string;
	firstName: string;
	lastName: string;
	pronouns?: string;
	userId: string;
}

export enum SkillLevel {
	All = 0,
	Beginner = 1,
	Intermediate = 2,
	Advanced = 3,
	SuperAdvanced = 4,
}

export const SKILL_LEVEL_STRINGS: Record<SkillLevel, string> = {
	[SkillLevel.All]: 'All',
	[SkillLevel.Beginner]: 'Beginner',
	[SkillLevel.Intermediate]: 'Intermediate',
	[SkillLevel.Advanced]: 'Advanced',
	[SkillLevel.SuperAdvanced]: 'Super Advanced',
}

export interface Member {
	anonymous: boolean;
	avatarUrl?: string;
	discordHandle?: string;
	email?: string;
	firstName?: string;
	gamerHandle?: string;
	hasOutstandingPayables?: boolean;
	hasParentConsent: boolean;
	hasVerifiedEmail: boolean;
	isEligible?: boolean;
	isUnder13: boolean;
	isUnverifiedAccount: boolean;
	lastName?: string;
	pronouns?: string;
	userEntityRoleId: string;
	userId?: string;
	username?: string;
}
export interface Organization extends NamedObject {
	city?: string;
	emailDomains?: string[];
	joinCode?: string;
	logoUrl?: string;
	numberOfTeams: number;
	phoneNumber?: string;
	postalCode?: string;
	restrictRegistrationToWhitelistedDomains: boolean;
	showPublicJoinUrl: boolean;
	state?: string;
	streetAddress?: string;
}

export interface JoinableOrganization extends NamedObject {
	joinCode: string;
}

export interface BasicOrganization extends NamedObject {
	showPublicJoinUrl: boolean;
}

export interface PaginatedOptions {
	page: number;
	pageSize: number;
	search?: string;
}

export interface PaginatedResult<TResult> {
	page: number;
	pageSize: number;
	results: TResult[];
	total: number;
	totalPages: number;
}

export interface IncrementalOptions {
	incrementalItems: number;
	startTimeUtc?: Date;
}

export interface IncrementalResult<TResult> {
	lastResultTimeUtc?: Date;
	remaining: number;
	results: TResult[];
}

export interface OrganizationMember extends Member {
	chatDisabled: boolean;
	emailVerificationTokens?: string[];
	roleId: OrganizationRoleId;
	userInviteToken?: string;
}

export interface OrganizationGame {
	gameId: string;
	gameName: string;
	teamCount: number;
}

export interface UnregisteredOrganizationMember {
	email: string;
	userInviteToken: string;
}

export enum OrganizationRoleId {
	Manager = 'f2c2b5f0-1c3c-4e12-8cde-886efa0de73e',
	Member = '8cf9bb78-51a7-4cbf-a428-3ed9bda097b0',
}

export interface Team extends NamedObject {
	currentSeason?: ParticipantSeason;
	currentSeasonId?: string;
	designation?: LeagueDesignation;
	designationId?: string;
	fulfillments: PayableFulfillment[];
	game?: Game;
	gameId?: string;
	joinCode?: string;
	isRosterLocked?: boolean;
	logoUrl?: string;
	members: TeamMember[];
	openToFreeAgents: boolean;
	organization?: Organization;
	organizationId: string;
	organizationLogoUrl?: string;
}

export interface ParticipantSeason {
	canLeaveSeason: boolean;
	id: string;
	isParticipating: boolean;
	season: Season;
}

export interface TeamMember extends Member {
	roleId: TeamRoleId;
}

export enum TeamRoleId {
	Captain = '5a1675f0-2fa9-482b-b187-434901734a42',
	Coach = '6f4da22c-7fe5-4c78-8876-eec2c87d1096',
	Player = '21140ea7-a115-49db-b6d7-b118979e2ede',
}

export enum RoleId { }

export interface HelpfulLink {
	disablePublic: boolean;
	id: string;
	name: string;
	sortOrder: string;
	url: string;
}

export interface DefaultImageUrls {
	league: string;
	organization: string;
	team: string;
	user: string;
}

export interface LeagueConfiguration {
	childLeagues: BasicLeague[];
	clubOrganization?: BasicOrganization;
	defaultImageUrls: DefaultImageUrls;
	designations: LeagueDesignation[];
	domain: string;
	helpfulLinks: HelpfulLink[];
	inviteCodes: string[];
	league: League;
	leagueFee?: Payable;
	parentLeague?: BasicLeague;
}

export enum InvalidInviteTokenReason {
	InvalidToken = 1,
	AlreadyFulfilled = 2,
	InviteRemoved = 3,
	LeagueNotSpecified = 4,
	UserBanned = 5,
}

export interface ValidateTokenResponse {
	invalidReason?: InvalidInviteTokenReason;
	isValid?: boolean;
	organization?: Organization;
	roleId: string;
	userExists?: boolean;
}

export interface VerifyUserEmailResponse {
	isFulfilled: boolean;
	isParentToken: boolean;
}

export interface ValidateJoinCodeResponse {
	isValid?: boolean;
	invalidReason?: string;
	organization: Organization;
}

export interface CompetitionReadyItem {
	authorizeUrl?: string;
	handleSourceIconSvgUrl?: string;
	handleSourceName?: string;
	isCritical: boolean;
	leagueId?: string;
	matchId?: string;
	name?: string;
	oAuthProviderId?: string;
	order: number;
	type: CompetitionReadyItemType;
	verificationState?: UserIdentityVerificationSubmissionState;
}

export interface GetCompetitionReadyStatusResponse {
	isReady: boolean;
	items: CompetitionReadyItem[];
}

export interface ValidateEmailCommand {
	email: string,
	organizationId?: string,
}

export interface ValidateUsernameCommand {
	action: RegistrationAction,
	organizationId?: string,
	username: string,
}

export interface EditOrganizationCommand {
	emailDomains?: string[];
	id: string;
	name: string;
	phoneNumber?: string;
	restrictRegistrationToWhitelistedDomains: boolean;
	showPublicJoinUrl: boolean;
}

export interface UploadLogoCommand {
	id: string;
	imageData: string;
}

export interface UploadLeagueImageCommand {
	imageData: string;
	imageType: LeagueImageType;
}

export enum LeagueImageType {
	Banner = 0,
	HeaderLogo = 1,
	Logo = 2,
	Favicon = 3,
}

export interface InvalidateNotificationTokenCommand {
	token?: string;
}

export interface InviteToOrganizationCommand {
	organizationId: string;
	roleId: OrganizationRoleId;
	users: { email: string }[];
}

export interface ResendUserInvitesCommand {
	organizationId: string;
	userInviteTokens: string[];
}

export interface InviteToLeagueCommand {
	leagueId: string;
	roleId: LeagueRoleId;
	emails: string[];
}

export interface NewUserJoinCommand {
	agreedToLicenseAgreementAtRegistration: boolean;
	discordHandle?: string;
	firstName: string;
	inviteCode: string;
	lastName: string;
	password: string;
	preferredTimezone?: string;
}

export interface ExistingUserJoinCommand {
	discordHandle?: string;
	inviteCode: string;
	password: string;
}

export interface VerifyUserEmailCommand {
	token: string;
}

export interface IdentifierValidationResult {
	canProceed: boolean;
	error?: string;
	hasFulfilledPayable?: boolean;
	hasOrganizationRole?: boolean;
	isInUse: boolean;
}

export interface ResendVerifyUserEmailCommand {
	userId: string;
	verificationType: VerificationType;
}

export enum VerificationType {
	All = 0,
	Email = 1,
	ParentConsent = 2,
}

export interface ThemeColors {
	accent1: string;
	accent2: string;
	backgroundColor: string;
	error: string;
	primaryButtonTextColor: string;
	primaryColor: string;
	primaryTextColor: string;
	secondaryButtonTextColor: string;
	secondaryColor: string;
	success: string;
}
export enum ColorOptions {
	Primary = 'primary',
	Secondary = 'secondary',
	Accent1 = 'accent1',
	Accent2 = 'accent2',
	Success = 'success',
	Error = 'error',
}
export enum Shades {
	Light80 = 'light-80',
	Light60 = 'light-60',
	Light40 = 'light-40',
	Light20 = 'light-20',
	Dark20 = 'dark-20',
	Dark40 = 'dark-40',
	Dark60 = 'dark-60',
	Dark80 = 'dark-80'
}

export enum RadiusSizes {
	XS = 'xs',
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
	XL = 'xl'
}

export interface AddLeagueHelpfulLinkCommand {
	name: string;
	url: string;
	disablePublic: boolean;
}

export interface UpdateLeagueHelpfulLinkOrderRequest {
	leagueHelpfulLinkIds: string[];
}

export interface CreateLeagueRequest {
	agreedToLicenseAgreementAtRegistration?: boolean;
	email?: string;
	firstName?: string;
	inviteCode: string;
	lastName?: string;
	name: string;
	password: string;
	preferredTimezone?: string;
	subdomain: string;
	timezone: string;
	username: string;
}

interface LeagueAdminSettings {
	allowU13: boolean
	bypassEmailVerificationRestrictions: boolean;
	customRegistrationFieldsEnabled: boolean;
	disableDiscordNotifications: boolean;
	disableInvites: boolean;
	enablePaymentFeatures: boolean;
	enableSeasonAgeRestrictions: boolean;
	enableSocialFeatures: boolean;
	featureGroup: FeatureGroup;
	isBilling: boolean;
	legalEntity: string;
	legalUrl: string;
	parentLeagueId?: string;
	u13ConsentTerms: string;
}

export interface EditLeagueRequest {
	adminSettings?: LeagueAdminSettings;
	description: string;
	disableDiscordCompetingStatus: boolean;
	disableDms: boolean;
	discordUrl: string;
	email: string;
	enableChat: boolean;
	id: string;
	identityVerificationInstructions: string;
	legalEntity: string;
	name: string;
	organizationTerm: string;
	phoneNumber?: string;
	requireIdentityVerification: boolean;
	restrictRegistrationToWhitelistedDomains: boolean;
	timezone: string;
	truncatePlayerNames: boolean;
	websiteUrl: string;
}

export interface ResetPasswordValues {
	code: string;
	userId: string;
	newPassword: string;
}

export interface SearchResult {
	type: 'Organization' | 'Season' | 'Team' | 'User';
	id: string;
	entityName: string;
	userFirstName: string;
	userLastName: string;
	userPronouns?: string;
}

export interface GameHandle {
	alwaysShow: boolean;
	authorizeUrl?: string;
	handle?: string;
	handleSourceId: string;
	handleSourceIconSvgUrl?: string;
	handleSourceName: string;
	handleSourceType: HandleSourceType;
	id?: string;
	oAuthProviderId?: string;
	requiresRelink: boolean;
}

export interface UserGameMetadata extends BaseObject {
	game: Game;
	gameId: string;
	type: UserGameMetadataType;
	userId: string;
	value: string;
}

export enum UserGameMetadataType {
	Ranking = 0,
}

export interface Ranking {
	key: string;
	value: number;
}

export enum HandleSourceType {
	GameHandleSource = 0,
	OAuthProvider = 1,
	Other = 2,
}

export interface UserOrganizationRoleGameInterest {
	dates: string[];
	gameId: string;
	lastUpdatedUtc: string;
	skillLevel: SkillLevel;
	userOrganizationRoleId: string;
}

export interface GameInterests {
	games: Game[];
	lastUpdatedUtc: Date;
}

export interface UpdateUserGameInterestsCommand {
	userId: string;
	gameInterests: string[];
}

export interface OAuthProvider {
	authorizeUrl: string;
	id: string;
}

export interface UserProfile {
	avatarUrl?: string;
	birthdate?: string;
	discordHandle?: string;
	email?: string;
	firstName: string;
	fulfillments?: PayableFulfillment[];
	gameHandles?: GameHandle[];
	gameInterests?: GameInterests;
	gameMetadata?: UserGameMetadata[];
	gameProfiles: GameProfile[];
	gamerHandle?: string;
	hasParentConsent: boolean;
	hasVerifiedEmail: boolean;
	hidePublicGameProfiles: boolean;
	identityVerificationSubmission?: UserIdentityVerificationSubmission;
	isAdministrator: boolean;
	isUnder13: boolean;
	isUnverifiedAccount: boolean;
	lastName: string;
	leagueCustomFields?: CustomRegistrationFieldSubmissionResponse[];
	leagueProfile?: LeagueProfile;
	mfaEnabled: boolean;
	organizationId?: string;
	organizationLogoUrl?: string;
	organizationName?: string;
	parentEmail?: string;
	preferredTimezone?: string;
	pronouns?: string;
	sendDiscordReminders: boolean;
	sendEmailReminders: boolean;
	showDiscordCompetingStatus: boolean;
	userId: string;
	username?: string;
}

interface LeagueProfile {
	games: LeagueProfileGame[];
	seasons: LeagueProfileSeason[];
}

interface LeagueProfileGame {
	completedSeasons: number;
	gameIconUrl?: string;
	gameId: string;
	gameName: string;
	losses: number;
	teams: number;
	ties: number;
	wins: number;
}

interface LeagueProfileSeason {
	completed: boolean;
	completedMatches: number;
	gameIconUrl?: string;
	gameId: string;
	gameName: string;
	losses: number;
	placement?: number;
	seasonId: string;
	seasonName: string;
	teamId: string;
	teamName: string;
	ties: number;
	wins: number;
}

interface GameProfile {
	gameFeatureSet: FeatureSet;
	gameIconUrl?: string;
	gameId: string;
	gameName: string;
	userId: string;
	username: string;
}

export interface MyUserProfile {
	activeSeasons: Season[];
	age?: number;
	avatarUrl?: string;
	birthdate?: string;
	chatDisabled: boolean;
	email: string;
	emailConfirmed: boolean;
	firstName: string;
	freeAgents: SeasonFreeAgent[];
	gameInterests?: UserOrganizationRoleGameInterest[];
	gamerHandle?: string;
	hasFulfilledPayable?: boolean;
	hasParentConsent: boolean;
	hasVerifiedEmail: boolean;
	isEligible: boolean;
	isUnder13: boolean;
	isUnverifiedAccount: boolean;
	lastDismissedNotifierUtc?: string;
	lastName: string;
	organizationId?: string;
	organizationLogoUrl?: string;
	organizationName?: string;
	parentEmailConfirmed: boolean;
	preferredTimezone?: string;
	pronouns?: string;
	sendDiscordReminders: boolean;
	sendEmailReminders: boolean;
	teams: Team[];
	userId: string;
	username: string;
}

export interface UserIdentityVerificationSubmission {
	adminNotes?: string;
	expirationTimeUtc: string;
	imageUrl: string;
	lastUpdatedUtc: string;
	leagueId: string;
	state: UserIdentityVerificationSubmissionState;
	userId: string;
	userNotes?: string;
}

export interface IdentityVerificationSubmissionSummary {
	birthdate?: string;
	discordHandle: string;
	email: string;
	firstName: string;
	lastName: string;
	state: UserIdentityVerificationSubmissionState;
	userId: string;
}

export interface IdentityVerificationSubmissionDetails extends IdentityVerificationSubmissionSummary {
	adminNotes: string;
	imageUrl: string;
	userNotes: string;
}

export enum UserIdentityVerificationSubmissionState {
	Submitted = 0,
	MoreInformationRequired = 1,
	Rejected = 2,
	Approved = 3,
}

export interface GetIdentityVerificationSubmissionsSummaryRequest {
	state?: UserIdentityVerificationSubmissionState;
}

export interface GetIdentityVerificationSubmissionsSummaryResponse {
	submissions: IdentityVerificationSubmissionSummary[];
}

export interface UpdateUserIdentityVerificationSubmissionRequest {
	notes?: string;
	state: UserIdentityVerificationSubmissionState;
	userId: string;
}

export interface ChangeUserTeamRoleCommand {
	userTeamRoleId: string;
	newTeamRoleId: TeamRoleId;
}

export interface ChangeUserOrganizationRoleCommand {
	newOrganizationRoleId: OrganizationRoleId;
	userOrganizationRoleId: string;
}

export interface CreateUserTeamRoleCommand {
	teamId: string;
	teamRoleId: string;
	userOrganizationRoleIds: string[];
}

export interface EditUserProfileCommand {
	birthdate?: string;
	customRegistrationFields: CustomRegistrationFieldSubmissionRequest[];
	email?: string;
	firstName: string;
	gamerHandle?: string;
	id: string;
	lastName: string;
	parentEmail?: string;
	preferredTimezone?: string;
	pronouns?: string;
	username: string;
}

export interface AddGameHandleCommand {
	gameHandle: string;
	gameHandleSourceId: string;
	userId: string;
}

export interface AddGameRankingCommand {
	gameId: string;
	userId: string;
	value: string;
}

export interface EditGameRankingRequest {
	userGameMetadataId: string;
	value: string;
}

export interface AddBirthdateCommand {
	birthdate: string;
}

export interface SetChatAbilityRequest {
	chatDisabled: boolean;
	organizationId?: string;
	seasonId?: string;
	userId: string;
}

export interface CreateUserTeamRoleResponse {
	userOrganizationRoleId: string;
	userTeamRoleId: string;
	teamRoleId: TeamRoleId;
}

export interface OAuthState {
	bypassPlatformLogin?: boolean;
	oAuthProviderId: string;
	returnUrl: string;
}

export interface VerificationProviderStateParams {
	discordChannelId: number;
	discordGuildId: number;
	discordRoleId: number;
	discordUserId: number;
}

export interface GenericOAuthState extends OAuthState {
	userId?: string;
}

export interface VerificationProviderOAuthState extends OAuthState, VerificationProviderStateParams { }

export interface BaseOAuthCommand {
	code: string;
	oAuthProviderId: string;
	redirectUri: string;
}

export interface LinkOAuthAccountCommand extends BaseOAuthCommand { }

export interface VerifyLinkOAuthAccountCommand extends BaseOAuthCommand, VerificationProviderStateParams { }

export interface UnlinkOAuthAccountCommand {
	oAuthProviderId: string;
	userId: string;
}

export interface UnlinkAccountCommand {
	gameHandleSourceId: string;
	userId: string;
}

export interface DiscordUser extends BaseObject {
	guilds: DiscordGuild[];
	username: string;
}

export interface DiscordGuild extends NamedObject { }

export interface AddLeagueApprovedGameCommand {
	gameId: string;
}

export interface ChangePasswordCommand {
	currentPassword: string;
	newPassword: string;
}

export enum StyleType {
	Primary = 'primary',
	Secondary = 'secondary',
	Tertiary = 'tertiary',
	LeaguePrimary = 'league-primary',
	LeagueSecondary = 'league-secondary',
	OutlineOnly = 'outline-only',
	TextOnly = 'text-only',
	LinkLike = 'link-like',
}

export interface Stage extends NamedObject {
	allMatchesAreComplete: boolean;
	announcements: StageAnnouncement[];
	appliedAutoTiebreakers: boolean;
	bestOf: number;
	competitionStyle: CompetitionStyle;
	currentRoundId?: string;
	currentState: StageState;
	eligibleParticipants: EligibleStageParticipant[];
	groups: Group[];
	locationId?: string;
	participants: StageParticipant[];
	randomizeLeaderboardParticipants?: boolean;
	rounds: Round[];
	season?: Season;
	seasonId: string;
	settings: StageSettings;
	sortOrder: number;
	stageTypeId: StageTypeId;
	startDateUtc: string;
	useSimplifiedCheckin: boolean;
}

export interface StageAnnouncement {
	createdAtUtc: string;
	id: string;
	message: string;
	sender: StageAnnouncementSender;
}

interface StageAnnouncementSender {
	firstName: string;
	id: string;
	lastName: string;
	pronouns?: string;
}

export interface EligibleStageParticipant extends BaseObject {
	checkedIn: boolean;
	inputRank: number;
	notifiedAtUtc?: string;
	participant: Participant;
}

export enum StageState {
	NotStarted = 0,
	CheckingIn = 1,
	InProgress = 2,
	IsComplete = 3,
}

export interface StageSettings {
	allowMatchGameTies: boolean;
	allowMatchTies: boolean;
	allowRescheduleRequests: boolean;
	attemptPointsBasedScoringTiebreaker: boolean;
	attendanceWindowMinutes?: number;
	autoForfeitWindowMinutes?: number;
	autoStartRounds?: boolean;
	inputParticipantSource: InputParticipantSourceType;
	locationId?: string;
	matchesPerRound?: number;
	numberOfGroups: number;
	numberOfInputParticipants: number;
	preScheduledDescription?: string;
	previousStageInputSourceId?: string;
	requireResultScreenshots: boolean;
	requireStageCheckIn: boolean;
	rescheduleCutoffDayOfWeek?: DayOfWeek;
	rescheduleCutoffMinutesAfterMidnight?: number;
	rosterLockHours?: number;
	standingsPointsForBye: number;
	standingsPointsForGameTie: number;
	standingsPointsForGameWin: number;
	standingsPointsForMatchLossByForfeit: number;
	standingsPointsForMatchLossByPlay: number;
	standingsPointsForMatchTie: number;
	standingsPointsForMatchWinByForfeit: number;
	standingsPointsForMatchWinByPlay: number;
	usePointsBasedScoring: boolean;
}

export interface Round extends NamedObject {
	autoStartDisabled: boolean;
	bestOf: number;
	currentState: CompetitionState;
	matches?: Match[];
	roundGroupParticipants?: RoundGroupParticipant[];
	sortOrder: number;
	stage?: Stage;
	startTimeUtc?: string;
}

export interface Group extends NamedObject {
	groupParticipants?: GroupParticipant[];
	matches?: Match[];
	numGroups?: number;
	sortOrder: number;
	stage?: Stage;
}

export interface SeasonMatches {
	matches: MatchSummary[];
}

export interface MatchSummary {
	participants: Participant[];
	bestOf: string;
	status: string;
	time: string;
	date: string;
}

export interface Participant extends NamedObject {
	agreedToSeasonTerms: boolean;
	avatarUrl?: string;
	hearthstoneDecks?: HearthstoneDeck[];
	isParticipating: boolean;
	organizationLogoUrl?: string;
	organizationName: string;
	teamId?: string;
}

export interface GroupParticipant extends Participant {
	byes: number;
	gamePointsRegulation: number;
	gamePointsTiebreakers: number;
	gameTies: number;
	gameWins: number;
	groupId: string;
	inputRank: number;
	isParticipating: boolean;
	lossesByForfeit: number;
	lossesByPlay: number;
	needsTieBreak: boolean;
	outputRank: number;
	participantId: string;
	points: number;
	suggestedInputRank?: number;
	stageInputRank: number;
	tieGroup?: number;
	ties: number;
	winsByForfeit: number;
	winsByPlay: number;
}

export interface RoundGroupParticipant {
	groupParticipant?: GroupParticipant;
	groupParticipantId: string;
	id: string;
	joinedTimeUtc: string;
	joinedUser?: JoinedRoundUser;
	roundId: string;
}

export interface JoinedRoundUser {
	firstName: string;
	id: string;
	lastName: string;
	pronouns?: string;
}

export interface SeasonEligibleTeam {
	captains: Captain[];
	hasNotEnoughPlayers: boolean;
	isParticipatingInAnotherSeason: boolean;
	isParticipatingInThisSeason: boolean;
	name: string;
	organizationLogoUrl: string;
	organizationName: string;
	rosterDenominator: number;
	rosterNumerator: number;
	teamId: string;
}

export interface Captain {
	firstName: string;
	lastName: string;
	pronouns?: string;
}

export interface SetSeasonTeamsDto {
	seasonId: string;
	teamIds: string[];
}

export interface StageParticipant extends Participant {
	participantId: string;
	inputRank: number;
}

export interface SetStageParticipantsCommand {
	id: string;
	participantIds: string[];
}

export enum StageTypeId {
	AutoMatcher = '2b6926e8-a556-4a6e-95aa-ee67677dd1fb',
	DoubleElimination = '4b18ec63-c29e-449e-b172-a4bb932802ea',
	DoubleRoundRobin = 'b9d8de70-7fbd-4e27-a733-b31deb96501b',
	Leaderboard = 'ebd35d99-124d-4689-844f-bd191a634af2',
	LeaderboardAutoMatcher = '53e27392-7028-4c92-b0c5-5157476e28c1',
	RoundRobin = '39884d97-461a-4f25-b3b5-4cb92f5959ac',
	SingleElimination = 'bdfe0525-fee4-4f1a-b3f7-76175933963f',
	Swiss = '45a236ff-2ed5-4750-a5ff-b2947fa2f972',
}

export enum Bracket {
	Standard = 0,
	Finals = 1,
	Upper = 2,
	Lower = 3,
}

export enum StageTimingMode {
	ManuallyStarted = 0,
	AutoScheduled = 1,
}

export enum InputParticipantSourceType {
	AllSeasonParticipants = 0,
	FromPreviousStage = 1,
	ManualList = 2,
}

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}

export enum CompetitionReadyItemType {
	AddHandle = 1,
	JoinSeason = 2,
	LinkDiscord = 3,
	LinkHandle = 4,
	ResolveReschedules = 5,
	VerifyEmail = 6,
	VerifyIdentity = 7,
	RelinkHandle = 8,
	VerifyParentConsent = 9,
	OutstandingLeagueFee = 10,
}

export interface Match extends BaseObject {
	bestOf: number;
	bracket: Bracket;
	competitionStyle: CompetitionStyle;
	currentState: MatchState;
	group?: Group;
	groupId: string;
	hostNotes: string;
	isDisputed?: boolean;
	leagueId: string;
	matchGames?: MatchGame[];
	matchLinks?: MatchLink[];
	matchParticipants: MatchParticipant[];
	numberInBracket?: number;
	rescheduleRequests?: RescheduleRequest[];
	round?: Round;
	roundId: string;
	scoringType: ScoringType;
	sortOrder: number;
	startTimeUtc?: string;
	threadId?: string;
}

export interface MatchGame extends BaseObject {
	hasScoringData: boolean;
	isComplete: boolean;
	isTiebreaker: boolean;
	results: MatchGameResult[];
	scoringCanBeRequestedAtUtc?: string;
	scoringError?: boolean;
	scoringParsed?: any;
	scoringStatus?: string;
	sortOrder: number;
	tennisStyle: boolean;
	tennisStyleSetNumber?: number;
	titleMetadata?: string;
}

export enum MatchLinkType {
	TeamsMeeting = 0,
}

export interface MatchLink extends BaseObject {
	type: MatchLinkType;
	url: string;
}

export interface AddMatchLinkCommand {
	matchId: string;
	type: MatchLinkType;
	url: string;
}

export interface AddMatchLobbyInstructionsCommand {
	lobbyInstructions: string;
	matchId: string;
}

export interface MatchGameResult extends BaseObject {
	scores: MatchGameResultScore[];
	screenshots?: string[];
	submittedByFirstName?: string;
	submittedByLastName?: string;
	submittedByPronouns?: string;
	submittedByUserId?: string;
	submittedTimeUtc: string;
}

export interface MatchGameResultScore {
	score: number;
	matchParticipantId: string;
}

export interface MatchParticipant extends NamedObject {
	avatarUrl?: string;
	fulfillments: PayableFulfillment[];
	gameTies: number;
	gameWins: number;
	groupInputRank: number;
	hasCheckedIn: boolean;
	hasForfeited?: boolean;
	hearthstoneDecks?: MatchHearthstoneDeck[];
	isParticipating?: boolean;
	isTie: boolean;
	isWinner: boolean;
	leagueDesignationId?: string;
	matchGameMetadata?: MatchGameMetadata[];
	organizationLogoUrl?: string;
	participantId: string;
	previousMatchId?: string;
	rejectRescheduleRequestsAtUtc?: string;
	requiresPreviousWin: boolean;
	rosterSize: number;
	score?: number;
	sortOrder: number;
	stageInputRank: number;
	stationId?: string;
	teamId?: string;
	users: MatchParticipantUser[];
}

export interface MatchParticipantUser {
	anonymous?: boolean;
	avatarUrl?: string;
	competitionReadyPendingItems: CompetitionReadyItemType[];
	discordHandle?: string;
	firstName: string;
	gameHandle?: GameHandle;
	gameRanking?: UserGameMetadata;
	gamerHandle?: string;
	hasParentConsent: boolean;
	hasVerifiedEmail: boolean;
	id?: string;
	isEligible?: boolean;
	isUnder13: boolean;
	isUnverifiedAccount: boolean;
	lastName: string;
	pronouns?: string;
	sortOrder: number;
	teamRoleId?: string;
	userId?: string;
}

export interface MatchParticipantOAuthAccount {
	matchParticipantUserId: string;
	providerUserId: string;
	providerUsername: string;
	teamId: string;
	teamName: string;
}

export interface TodaysMatch {
	competitionStyle: CompetitionStyle;
	currentState: MatchState;
	eventType: EventType;
	id: string;
	matchParticipants: TodaysMatchParticipant[];
	startTimeUtc: string;
	titleName: string;
}
export interface TodaysMatchParticipant {
	teamId?: string;
}
export interface MatchesTodayResponse extends HasResponseDate {
	matches: TodaysMatch[];
}

export interface MatchResponse extends HasResponseDate {
	match: MatchDetails;
}

export interface MatchDetails {
	bestOf: number;
	competitionStyle: CompetitionStyle;
	currentState: MatchState;
	game: {
		eventType: EventType;
		featureSet: FeatureSet;
		gameHandleSource?: GameHandleSource;
		iconUrl?: string;
		id: string;
		maximumLobbySize?: number;
		minimumSeats: number;
		name: string;
		rankings: { [key: string]: number };
		rankingTerm: string;
		rankingType: RankingType;
		resultFileExtensions?: string[];
	};
	groupName: string;
	hostNotes?: string;
	id: string;
	isDisputed: boolean;
	lobbyInstructions?: string;
	matchLinks?: MatchLink[];
	participants: {
		hasCheckedIn: boolean;
		hasForfeited: boolean;
		id: string;
		name?: string;
		participantId: string;
		rejectRescheduleRequestsAt: RejectRescheduleRequestsAt;
		rejectRescheduleRequestsAtUtc?: string;
		sortOrder: number;
		teamId?: string;
	}[];
	requireGameRanks: boolean;
	requireHandleForCheckin: boolean;
	roundName: string;
	roundState: CompetitionState;
	scoringType: ScoringType;
	season: {
		id: string;
		name: string;
		payableExemptLeagueDesignationIds: string[];
		payableId?: string;
		state: CompetitionState;
		tennisStyle: boolean;
		tennisStyleSets?: number;
		titleConfiguration?: ChessConfiguration;
	},
	stageId: string;
	stageName: string;
	stageSettings: StageSettings;
	stageState: StageState;
	stageTypeId: StageTypeId;
	startTimeUtc?: string;
	threadId?: string;
	useSimplifiedCheckin: boolean;
}

export interface MatchesResponse extends HasResponseDate {
	matches: Match[];
}

export interface MatchCheckinRequestBase {
	matchId: string;
}

export interface MatchCheckinRequest extends MatchCheckinRequestBase {
	participantId: string;
	userIds: string[];
}

export interface SimplifiedMatchCheckinRequest extends MatchCheckinRequestBase {
	participantIds: string[];
}

export interface UndoMatchCheckInCommand {
	matchId: string;
	participantId: string;
}

export enum CompetitionState {
	NotStarted = 0,
	InProgress = 1,
	IsComplete = 2,
}

export enum MatchState {
	NotStarted = 0,
	RescheduleRequested = 1,
	InProgress = 2,
	IsComplete = 3,
}

export interface HasResponseDate {
	responseDateUtc: Date;
	receivedDateUtc: Date;
}

export interface BaseEditStageCommand {
	allowMatchGameTies: boolean;
	allowMatchTies: boolean;
	allowRescheduleRequests: boolean;
	attemptPointsBasedScoringTiebreaker: boolean;
	attendanceWindowMinutes?: number;
	autoForfeitWindowMinutes?: number;
	autoStartRounds?: boolean;
	bestOf: number;
	dayOfWeek?: DayOfWeek;
	has3rdPlaceMatch: boolean;
	inputParticipantSource: InputParticipantSourceType;
	locationId?: string;
	minutesAfterMidnight?: number;
	name?: string;
	numberOfGroups: number;
	numberOfInputParticipants: number | null;
	numberOfRounds?: number;
	preScheduledDescription?: string;
	previousStageInputSourceId?: string;
	randomizeLeaderboardParticipants?: boolean;
	requireResultScreenshots: boolean;
	requireStageCheckIn: boolean;
	rosterLockHours?: number;
	stageTypeId: StageTypeId;
	standingsPointsForBye: number;
	standingsPointsForGameTie: number;
	standingsPointsForGameWin: number;
	standingsPointsForMatchLossByForfeit: number;
	standingsPointsForMatchLossByPlay: number;
	standingsPointsForMatchTie: number;
	standingsPointsForMatchWinByForfeit: number;
	standingsPointsForMatchWinByPlay: number;
	timingMode: StageTimingMode;
	usePointsBasedScoring: boolean;
	useSimplifiedCheckin: boolean;
}

export interface CreateStageCommand extends BaseEditStageCommand {
	seasonId: string;
}

export interface BasicStage extends BaseEditStageCommand {
	competitionStyle: CompetitionStyle;
	currentState: StageState;
	id: string;
	seasonId: string;
}

export interface EditStageCommand extends BaseEditStageCommand {
	id: string;
}

export interface TeamEligibleSeasonResult {
	activeParticipantSeasons?: ParticipantSeason[];
	eligibleSeasons: Season[];
	members: UserWithRankings[];
}

export interface UserWithRankings {
	firstName?: string;
	roleId?: string;
	userId?: string;
	lastName?: string;
	pronouns?: string;
	rankings: UserGameMetadata[];
}

export interface TeamMemberRanking {
	ranking?: string;
	userId?: string;
}

export interface TeamSeasonCommand {
	agreedToSeasonTerms?: boolean;
	teamId: string;
	seasonId?: string;
	teamMemberRankings?: TeamMemberRanking[];
}

export interface SetOpenToFreeAgentsRequest {
	openToFreeAgents: boolean;
	teamId: string;
}

export interface SetTeamJoinCodeRequest {
	shouldHaveJoinCode: boolean;
	teamId: string;
}

export interface CreateStageAnnouncementRequest {
	includeManagers: boolean;
	message: string;
	participantIds: string[];
	stageId: string;
}

export interface EditStageAnnouncementRequest {
	message: string;
	stageAnnouncementId: string;
}

export interface ParticipantIsParticipatingCommand {
	id: string;
	isParticipating: boolean;
}

export interface StartStageCommand {
	id: string;
	groupedRankedParticipants: Dictionary<number>[];
}

export interface OpenStageCheckinCommand {
	stageId: string;
}

export interface StageCheckinCommand {
	checkedIn: boolean;
	eligibleStageParticipantId: string;
}

export interface CreateEligibleParticipantCommand {
	seasonParticipantId: string;
	stageId: string;
}

export interface SubmitMatchGameResultCommand {
	autoScoringData?: string;
	matchGameId: string;
	scores?: SubmitResultScore[];
	screenshots: ResultFile[];
}

export interface RescoreMatchGameResultRequest {
	matchGameId: string;
}

export interface SubmitResultScore {
	matchParticipantId: string;
	score?: number;
}

export interface ResultFile {
	data: string;
	fileName: string;
}

export interface SubmitMatchResultCommand {
	matchId: string;
	scores: SubmitResultScore[];
}

export interface CreateRoundCommand {
	bestOf: number;
	name: string;
	stageId: string;
	startTimeUtc?: string;
}

export interface EditRoundCommand {
	id: string;
	name: string;
	startTimeUtc?: string;
	bestOf: number;
}

// Ignores Jobs-related props
export interface StartRoundCommand {
	id: string;
}

export interface JoinRoundCommand {
	roundId: string;
	groupParticipantId: string;
}

export interface EditGroupCommand {
	id: string;
	name: string;
}

export interface RescheduleRoundGroupCommand {
	id: string;
	roundId: string;
	startTimeUtc?: string;
}

export interface AssignGroupToStationRequest {
	groupId: string;
	stationId?: string;
}

export interface CreateMatchCommand {
	bestOf: number;
	groupId: string;
	groupParticipantIds: string[];
	roundId: string;
	startTimeUtc?: string;
}

export interface EditMatchCommand {
	id: string;
	startTimeUtc?: string;
	bestOf: number;
}

export interface CreateSeasonRequest {
	ageMax?: number;
	ageMin?: number;
	allowFreeAgents: boolean;
	allowedTeamDesignations: string[];
	enablePublicRegistration: boolean;
	enforceMinimumRoster: boolean;
	gameId: string;
	maxParticipants?: number;
	metadata?: string;
	name: string;
	requireHandleForCheckin: boolean;
	payableId?: string;
	requireGameRanks: boolean;
	seasonTerms?: string;
	skillLevel: SkillLevel;
	startDate?: string;
	tennisStyle: boolean;
	tennisStyleSets?: number;
	timingDescription?: string;
	titleConfiguration?: ChessConfiguration;
	userLeagueRoleIds: string[];
}

export interface EditSeasonRequest {
	allowFreeAgents: boolean;
	description?: string;
	enablePublicRegistration: boolean;
	enforceMinimumRoster: boolean;
	gameId: string;
	id: string;
	isVisible: boolean;
	maxParticipants?: number;
	metadata?: string;
	name: string;
	requireGameRanks: boolean;
	requireHandleForCheckin: boolean;
	seasonTerms?: string;
	skillLevel: SkillLevel;
	startDate?: string;
	tennisStyle: boolean;
	tennisStyleSets?: number;
	timingDescription?: string;
	titleConfiguration?: ChessConfiguration;
	userLeagueRoleIds: string[];
}

export interface AddLateSeasonParticipantCommand {
	groupId: string;
	seasonId: string;
	stageId: string;
	teamId: string;
}

export interface CreateSeasonFreeAgentRequest {
	seasonId: string;
}

export interface LeaveSeasonFreeAgentRequest {
	seasonId: string;
	userId: string;
}

export interface SetFreeAgentTeamsRequest {
	seasonId: string;
	teams: {
		organizationId: string;
		teamId?: string;
		userIds: (string | undefined)[];
	}[];
}

export interface SeasonFreeAgent {
	id: string;
	isActive: boolean;
	joinedTimeUtc: string;
	organizationId: string;
	organization?: Organization;
	seasonId: string;
	userId: string;
	user?: FreeAgentUser;
}

export interface FreeAgentUser {
	avatarUrl?: string;
	email: string;
	firstName: string;
	hasOutstandingPayables?: boolean;
	isUnder13: boolean;
	lastName: string;
	pronouns?: string;
	userId: string;
	username: string;
}

export interface SeasonFreeAgencyDetails {
	freeAgents: SeasonFreeAgent[];
	teams: Team[];
}

export interface JoinSeasonDetails {
	organizations: BasicOrganization[];
	season: Season;
}

export interface CanCompleteStageResponse {
	canComplete: boolean;
	reason?: string;
}

export interface OrganizationUserSetChatDisabledCommand {
	chatDisabled: boolean;
	userOrganizationRoleId: string;
}

export interface OrganizationUserSetIsEligibleCommand {
	userOrganizationRoleId: string;
	isEligible: boolean;
}

export interface CreateJoinOrganizationCodeCommand {
	organizationId: string;
}

export interface ManuallyBreakTiesCommand {
	stageId: string;
	groupParticipantIds: string[];
}

export interface AutoBreakTiesCommand {
	stageId: string;
}

export interface DisputeMatchCommand {
	id: string;
	isDisputed: boolean;
}

export interface ForfeitMatchCommand {
	matchId: string;
	participantId: string;
}

export interface ForfeitMatchAllCommand {
	matchId: string;
}

export interface UpdateHostNotesCommand {
	hostNotes: string;
	matchId: string;
}

export interface CreateLeagueDesignationCommand {
	color: string;
	iconName?: string;
	name: string;
}

export interface DeleteLeagueDesignationCommand {
	designationId: string;
}

export interface UpdateLeagueDesignationCommand {
	color?: string;
	designationId: string;
	iconName?: string;
	name?: string;
}

export interface CreateLeagueLocationCommand {
	description?: string;
	name: string;
	numberOfStations?: number | null;
}

export interface UpdateLeagueLocationCommand {
	description?: string;
	locationId: string;
	name: string;
	numberOfStations?: number | null;
}

export interface CreateLeagueLocationStationCommand {
	description?: string;
	locationId: string;
	name: string;
	systemName?: string;
}

export interface UpdateLeagueLocationStationCommand {
	description?: string;
	name: string;
	stationId: string;
	systemName?: string;
}

export interface CreateLeagueDesignationResponse {
	designation: LeagueDesignation;
}

export interface UpdateLeagueDesignationResponse {
	designation: LeagueDesignation;
}

export interface CreateLeagueLocationResponse {
	location: LeagueLocation;
}

export interface GetLeagueLocationsWithStationsResponse {
	locations: LeagueLocation[];
}

export interface UpdateLeagueLocationResponse {
	location: LeagueLocation;
}

export interface CreateLeagueLocationStationResponse {
	station: LeagueLocationStation;
}

export interface UpdateLeagueLocationStationResponse {
	station: LeagueLocationStation;
}

export interface RelevantGroupStandingsResult {
	participantIds: string[];
	rankedGroups: Group[];
	eliminationGroups: Group[];
}

export interface RelevantGroupMergedStandingsResult {
	participantIds: string[];
	groups: Group[];
}

export interface UserMatches {
	participantIds: string[];
	matches: Match[];
}

export interface MatchHistory {
	match: Match;
	seasonId: string;
	seasonName: string;
}

export interface LeagueRole extends NamedObject { }

export enum LeagueRoleId {
	Host = 'c012f6c5-a659-4c27-993c-58fd968237ff',
	LeagueAdmin = '9053c78e-f3e5-4139-8b02-d9e033ba506d',
	SeasonOperator = '4181ea2d-66b9-4da1-8eb4-2f17d726089f',
}

export interface LeagueMember {
	email: string;
	firstName: string;
	lastName: string;
	pronouns?: string;
	role: LeagueRole;
	seasonIds: string[];
	userLeagueRoleId: string;
	userId: string;
	username: string;
}

/**
 * API calls to the server will typically throw an exception that looks like this (but trust nothing).
 */
export interface AppError extends Error {
	response?: {
		data?:
		| {
			errors?: { [k: string]: string };
			title?: string;
		}
		| string;
		headers: { [key in 'date' | 'retry-after']: string };
		status: number;
	};
}

export interface File {
	blob: Blob;
	filename?: string;
}

export interface TwitchStream {
	channelName: string;
	id: string;
	live: boolean;
	thumbnailUrl?: string; // this should only exist if the stream is live
	title?: string; // this should only exist if the stream is live
	username?: string; // this should only exist if the stream is live
}

export interface Stream {
	isEnabled: boolean;
	id: string;
	sortOrder: number;
	username: string;
}

export enum StreamStatus {
	Offline = 0,
	Online = 1,
}

export interface CreateLeagueStreamRequest {
	username: string;
}

export interface EditStreamRequest extends CreateLeagueStreamRequest {
	streamId: string;
}

export interface CreateMatchStreamRequest {
	username: string;
	matchId: string;
}

export interface EnableStreamRequest {
	isEnabled: boolean;
	streamId: string;
}

export enum StreamEntityType {
	League = 0,
	Match = 1,
}

export interface MyDiscordStatus {
	authorizeUrl: string;
	status: DiscordStatus;
}

export enum DiscordStatus {
	NotLinked = 0,
	RequiresRelink = 1,
	Expired = 2,
	Linked = 3,
}

// chat
export interface ChatThread extends NamedObject {
	createdTimeUtc: Date;
	latestMessage?: MinimalChatMessage;
	latestMessageReadId?: string;
	matchId?: string;
	moderatorRequested: boolean;
	participants: ChatThreadParticipant[];
	seasonId?: string;
}

export interface UnreadThread extends BaseObject { }

interface MinimalChatMessage extends BaseObject {
	sentTimeUtc: Date;
}

export interface ChatThreadParticipant {
	addedTimeUtc: Date;
	avatarUrl: string;
	isProgrammatic: boolean;
	name: string;
	removedTimeUtc?: Date;
	threadId: string;
	userId: string;
}

export interface ChatThreadMessage extends BaseObject {
	author: ChatThreadParticipant;
	content: string;
	redacted: boolean;
	sentTimeUtc: Date;
	threadId: string;
}

// payment
export interface GatewayAccount {
	bankAccount?: BankAccount;
	business?: Business;
	id: string;
	payoutsEnabled: boolean;
	requiresAction: boolean;
}

export interface BankAccount {
	bankName: string;
	id: string;
	last4: string;
	routingNumber: string;
}

interface Business {
	name: string;
}

export enum AccountLinkType {
	Onboarding = 0,
	Update = 1,
}

export interface AccountLink {
	expiresAtUtc: string;
	url: string;
}

export interface HasAddress {
	addressCity: string;
	addressLine1: string;
	addressPostalCode: string;
	addressState: string;
}

export enum PaymentGateway {
	PayPal = '1d34ba3f-46a6-4643-a487-103376bffbdc',
	Stripe = '8bac39e5-9145-48e8-befa-80abd2d33b5d',
}

export interface Payable {
	discountFee?: number;
	discountEndUtc?: string;
	endDate?: string;
	entityType: PayableEntityType;
	exemptLeagueDesignations?: LeagueDesignation[];
	forLeagueDesignations?: LeagueDesignation[];
	fee: number;
	id: string;
	name: string;
	netRevenue: number;
	startDate?: string;
	timesOverridden: number;
	timesPaid: number;
	timesRefunded: number;
	totalRevenue: number;
}

export enum PayableEntityType {
	Season = 0,
	League = 1,
	TeamDesignation = 2
}

export interface UpsertPayableRequest {
	discountFee?: string;
	discountEndUtc?: string;
	endDate?: string;
	entityType: PayableEntityType;
	fee?: string; // `string` to support both '$50.00' in the form, as well as 5000 in the POST
	leagueDesignationIds: string[]; // Team designations excempt from season fees 
	forLeagueDesignationIds: string[]; // Team designations included in team designation fees
	name: string;
	payableId?: string;
	startDate?: string;
}

export interface AttachGatewayBankAccountRequest {
	bankAccountToken: string;
	paymentGatewayId: string;
}

export interface CreatePaymentMethodRequest {
	paymentGatewayId: string;
	paymentMethodId: string;
}

export interface PayableFulfillment {
	amount: number;
	complete: boolean;
	entityType: PayableFulfillmentEntityType;
	fulfilledDateUtc: Date;
	id: string;
	overridden: boolean;
	payable?: Payable;
	payableId: string;
	payerUserId: string;
	refunded: boolean;
	seasonIds: string[];
	teamId: string;
	teamName: string;
	userId: string;
	userName: string;
}

export enum PayableFulfillmentEntityType {
	Season = 0,
	League = 1,
	TeamDesignation = 2
}

export interface PaymentMethod {
	accountType: PaymentMethodAccountType;
	brand: string;
	expMonth: number;
	expYear: number;
	fundingSource: string;
	id: string;
	last4: string;
	paymentMethodId: string;
}

export enum PaymentMethodAccountType {
	Card = 0,
	Unknown = 99,
}

// reschedule requests
export interface RescheduleRequest {
	id: string;
	match: Match;
	matchId: string;
	proposedTimeUtc: string;
	state: RescheduleState;
	votes: RescheduleRequestVote[];
}

export enum RescheduleState {
	Pending = 0,
	Approved = 1,
	Rejected = 2,
	Cancelled = 3,
}

export interface RescheduleRequestVote {
	id: string;
	matchParticipant: MatchParticipant;
	matchParticipantId: string;
	rescheduleRequest: RescheduleRequest;
	rescheduleRequestId: string;
	response: RescheduleRequestVoteResponse;
	user: RescheduleRequestVoter;
	userId: string;
}

export enum RescheduleRequestVoteResponse {
	Approved = 0,
	Rejected = 1,
}

export interface RescheduleRequestVoter {
	firstName: string;
	lastName: string;
	pronouns?: string;
	userId: string;
}

export interface RejectReschedulesCommand {
	matchId: string;
	matchParticipantId: string;
	rejectRescheduleRequestsAt: RejectRescheduleRequestsAt;
}

export enum RejectRescheduleRequestsAt {
	None = 0,
	All = 1,
	OneDayBeforeMatchStart = 2,
}

export interface CreateRescheduleRequestCommand {
	proposedTimeUtc: string;
	matchId: string;
}

export interface VoteOnRescheduleRequestCommand {
	autoReject?: boolean;
	isApproving: boolean;
	matchParticipantId: string;
	rescheduleRequestId: string;
}

// hearthstone
export interface HearthstoneDeck {
	code: string;
	name: string;
	sortOrder: number;
}

export interface MatchHearthstoneDeck extends HearthstoneDeck {
	isBanned: boolean;
}

export interface SubmitHearthstoneDecksRequest {
	decks: string[];
	participantId: string;
}

export interface BanOpponentDeckRequest {
	deckCode: string;
	matchParticipantId: string;
}

export interface MatchGameMetadata {
	id: string;
	matchGameId: string;
	matchParticipantId: string;
	value?: ChessMetadata | HearthstoneMetadata | TennisStyleGameplayMetadata;
}

export interface HearthstoneMetadata {
	selectedDeckCode: string;
}

export interface TennisStyleGameplayMetadata {
	matchParticipantUserId: string;
}

export interface ChessMetadata extends TennisStyleGameplayMetadata {
	baseTimeControl: string;
	color: ChessColor;
	opponentMatchParticipantUserId: string;
	opponentUsername: string;
	timeIncrement: string;
}

export enum ChessColor {
	White = 1,
	Black = 2,
}

export interface CreateMatchGameMetadataRequest {
	matchGameId: string;
	matchParticipantId: string;
	value: string;
}

export interface EditMatchGameMetadataRequest {
	matchGameMetadataId: string;
	value: string;
}

export enum RegistrationAction {
	None = 0,
	CreateLeague = 1,
	CreateOrganization = 2,
	JoinLeagueViaInvite = 3,
	JoinOrganizationViaInvite = 4,
	JoinOrganizationViaJoinCode = 5,
	JoinSeason = 6,
}

export enum UserMatchListType {
	All = 0,
	History = 1,
	Upcoming = 2,
}

export interface DisableUserMfaRequest {
	userId: string;
}

export interface EnableUserMfaRequest {
	otpCode: string;
}

export interface EnableUserMfaResponse {
	success: boolean;
}

export interface GenerateOtpLinkResponse {
	otpLink: string;
	secret: string;
}

export interface CreateMatchParticipantRequest {
	groupParticipantIds: string[];
	matchId: string;
}

export interface ValorantMatchHistory {
	gameStartTimeUtc: string;
	matchId: string;
}

export interface ValorantMatchSummary {
	durationInSeconds: number;
	gameMode?: string;
	gameStartTimeUtc: string;
	map?: string;
	season: string;
	teams: ValorantMatchSummaryTeam[];
}

export interface ValorantMatchSummaryTeam {
	name: string;
	players: ValorantMatchSummaryPlayer[];
	points: number;
	tied: boolean;
	winner: boolean;
}

export interface ValorantMatchSummaryPlayer {
	agentIconUrl?: string;
	agentName?: string;
	assists: number;
	deaths: number;
	kills: number;
	tagLine: string;
	username: string;
}

export interface AssignMatchParticipantStationCommand {
	matchParticipantId: string;
	stationId?: string;
}

export interface ChessMatchSummary {
	black: ChessMatchSide;
	draw: boolean;
	fen: string;
	gameEndTimeUtc: string;
	id: string;
	pgn?: string[][];
	timeClass: string;
	timeClassIconUrl: string;
	timeControl?: string;
	url: string;
	white: ChessMatchSide;
}

export interface ChessMatchSide {
	name: string;
	rating: number;
	username: string;
	won: boolean;
}

export namespace ValorantParsedGameResult {
	export interface MatchGameScore {
		autoScoringTeamId: string;
		matchParticipantId?: string;
	}

	interface MediaItem {
		imageUrl: string;
		name: string;
	}

	export interface Participant {
		assists: number;
		agent: MediaItem;
		bodyshots: number;
		damage: number;
		deaths: number;
		defuses: number;
		firstDeaths: number;
		firstKills: number;
		headshots: number;
		id: string;
		kills: number;
		legshots: number;
		multi2Kills: number;
		multi3Kills: number;
		multi4Kills: number;
		multi5Kills: number;
		plants: number;
		roundsPlayed: number;
		score: number;
		tagLine: string;
		totalShots: number;
		username: string;
	}

	export interface Result {
		durationInSeconds?: number;
		endTimeUtc?: string;
		gameMode: string;
		id: string;
		map: string;
		rounds: Round[];
		scoringData?: ScoringData;
		season: string;
		startTimeUtc?: string;
		teams: Team[];
	}

	export interface Round {
		attackingTeam: string;
		defendingTeam: string;
		result: string;
		resultCode: string;
		resultIconUrl: string;
		winningTeam: string;
	}

	interface ScoringData {
		scores: MatchGameScore;
	}

	export interface Team {
		assists: number;
		damage: number;
		deaths: number;
		id: string;
		kills: number;
		matchParticipantId?: string;
		name: string;
		participants: Participant[];
		points: number;
		roundsPlayed: number;
		roundWins: number;
		score: number;
		tied: boolean;
		winner: boolean;
	}
}

export interface LeagueSearchResult {
	id: string;
	logoUrl: string;
	members: number;
	name: string;
	seasons: number;
	titles: number;
	url: string;
}

export namespace UserSearch {
	export interface Result extends BaseObject {
		avatarUrl?: string;
		birthdate?: string;
		createdAtUtc?: string;
		email: string;
		firstName: string;
		gameAccounts: GameAccount[];
		gamerTag?: string;
		isAdministrator: boolean;
		isEmailConfirmed: boolean;
		isMfaEnabled: boolean;
		isParentEmailConfirmed: boolean;
		lastName: string;
		linkedAccounts: GameAccount[];
		memberships: Membership[];
		parentEmail?: string;
		username: boolean;
	}

	export interface GameAccount extends NamedObject {
		iconUrl?: string;
		username: string;
	}

	export interface Membership extends BaseObject {
		leagueId: string;
		leagueName: string;
		leagueUrl: string;
		logoUrl: string;
		organizationId: string;
		organizationName: string;
	}
}

export enum ReactionType {
	Fire = 0,
}

export interface Post {
	content: string;
	createdAtUtc: string;
	gameId?: string;
	id: string;
	matchId?: string;
	numberOfComments: number;
	numberOfReactions: { [key: number]: number };
	reactions: Reaction[];
	seasonId?: string;
	user: PostAuthorUser;
	userId: string;
}

export interface Comment {
	content: string;
	createdAtUtc: string;
	id: string;
	postId: string;
	user: PostAuthorUser;
	userId: string;
}

export interface Reaction {
	id: string;
	postId: string;
	type: ReactionType;
	userId: string;
}

export interface PostAuthorUser {
	avatarUrl: string;
	firstName: string;
	gamerHandle: string;
	id: string;
	isUnder13: boolean;
	lastName: string;
	pronouns: string;
}

export interface UpdateReactionRequest {
	postId: string;
	type?: ReactionType;
}
