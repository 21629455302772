import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps, Form, FormikActions } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as Core from '../../core';
import { IconButton, SolidButton } from '../../components/buttons-visuals';
import { ContentContainer } from '../../components/containers';
import FormField from '../../components/formField';
import InfoMessage from '../../components/infoMessage';
import { FieldSet } from '../../components/inputs';
import MarkdownPreview from '../../components/markdown/markdownPreview';
import { Popover, ToolTip } from '../../components/overlays';
import useHashLinks from '../../hooks/hashLinks';
import { useHasLeagueAccess, useIsLite, useLeague, useOrganizationTerm } from '../../hooks/store';
import { LeagueService } from '../../services/leagueService';
import { getLeague } from '../../store/league/actions';

import './index.scss';

interface EditLeagueFormValues {
    allowU13: boolean;
    bypassEmailVerificationRestrictions: boolean;
    customRegistrationFieldsEnabled: boolean;
    description: string;
    disableDiscordCompetingStatus: boolean;
    disableDiscordNotifications: boolean;
    disableDms: boolean;
    disableInvites: boolean;
    disablePfp: boolean;
    discordUrl: string;
    email: string;
    enableChat: boolean;
    enablePaymentFeatures: boolean;
    enableSeasonAgeRestrictions: boolean;
    enableSocialFeatures: boolean;
    featureGroup: Core.Models.FeatureGroup;
    hideFromSearch: boolean;
    id: string;
    identityVerificationInstructions: string;
    isBilling: boolean;
    legalEntity: string;
    legalUrl: string;
    membersCanCreateTeams: boolean;
    name: string;
    organizationTerm: string;
    parentLeagueId?: string;
    phoneNumber: string;
    requireIdentityVerification: boolean;
    restrictRegistrationToWhitelistedDomains: boolean;
    timezone: string;
    truncatePlayerNames: boolean;
    u13ConsentTerms: string;
    websiteUrl: string;
}

const buildSchema = (canElevatedEditLeague: boolean): any => {
    const schema = {
        description: Yup.string().notRequired().max(Core.Constants.MARKDOWN_MAX_LENGTH),
        disableDiscordCompetingStatus: Yup.boolean(),
        disableDms: Yup.boolean(),
        disablePfp: Yup.boolean(),
        discordUrl: Yup.string().url('Discord URL is not valid').nullable().notRequired(),
        email: Yup.string()
            .email('Email must be formatted like an email address')
            .nullable()
            .notRequired()
            .max(Core.Constants.EMAIL_MAX_LENGTH),
        enableChat: Yup.boolean(),
        hideFromSearch: Yup.boolean(),
        identityVerificationInstructions: Yup.string().nullable().notRequired().max(Core.Constants.MARKDOWN_MAX_LENGTH),
        membersCanCreateTeams: Yup.boolean(),
        name: Yup.string().required('Name is required').max(Core.Constants.NAME_MAX_LENGTH),
        organizationTerm: Yup.string()
            .required('Organization term is required')
            .min(3)
            .max(Core.Constants.NAME_MAX_LENGTH),
        phoneNumber: Yup.string().nullable().notRequired().min(7).max(Core.Constants.PHONE_NUMBER_MAX_LENGTH),
        requireIdentityVerification: Yup.boolean(),
        restrictRegistrationToWhitelistedDomains: Yup.boolean(),
        timezone: Yup.string().required('Timezone is required'),
        truncatePlayerNames: Yup.boolean(),
        websiteUrl: Yup.string().url('Website URL is not valid').nullable().notRequired(),
        ...(canElevatedEditLeague && {
            allowU13: Yup.boolean(),
            bypassEmailVerificationRestrictions: Yup.boolean(),
            customRegistrationFieldsEnabled: Yup.boolean(),
            disableDiscordNotifications: Yup.boolean(),
            disableInvites: Yup.boolean(),
            enablePaymentFeatures: Yup.boolean(),
            enableSeasonAgeRestrictions: Yup.boolean(),
            enableSocialFeatures: Yup.boolean(),
            featureGroup: Yup.number().required(),
            isBilling: Yup.boolean(),
            legalEntity: Yup.string().required('Legal entity name is required').max(100),
            legalUrl: Yup.string().nullable().max(Core.Constants.URL_MAX_LENGTH),
            parentLeagueId: Yup.string()
                .nullable()
                .length(36, 'Parent League ID must be exactly 36 characters')
                .matches(
                    new RegExp('[a-fA-Fd0-9]{8}-([a-fA-Fd0-9]{4}-){3}[a-fA-Fd0-9]{12}'),
                    'Parent League ID is not formatted properly'
                ),
            u13ConsentTerms: Yup.string().nullable().notRequired().max(Core.Constants.MARKDOWN_MAX_LENGTH),
        }),
    };

    return Yup.object().shape(schema);
};

const BasicSettings = (): JSX.Element => {
    useHashLinks();

    const dispatch = useDispatch();
    const league = useLeague();
    const organizationTerm = useOrganizationTerm({ lowercase: true });
    const canElevatedEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.ElevatedEdit);
    const isLite = useIsLite();

    if (!league) return <></>;
    return (
        <ContentContainer className="global-container-centered" shade={Core.Models.Shades.Dark40}>
            <Formik<EditLeagueFormValues>
                initialValues={Object.assign(
                    {
                        description: league.description || '',
                        discordUrl: league.discordUrl || '',
                        email: league.email || '',
                        hideFromSearch: league.hideFromSearch,
                        identityVerificationInstructions: league.identityVerificationInstructions || '',
                        phoneNumber: league.phoneNumber || '',
                        timezone: league.timezone || '',
                        websiteUrl: league.websiteUrl || '',
                    },
                    league
                )}
                validationSchema={buildSchema(canElevatedEditLeague)}
                onSubmit={async (values: EditLeagueFormValues, actions: FormikActions<EditLeagueFormValues>) => {
                    actions.setStatus(undefined);
                    try {
                        await LeagueService.editLeague({ ...values, adminSettings: values });
                        toast.success('Successfully updated league');
                        dispatch(getLeague(true));
                    } catch (e) {
                        const message = Core.API.getErrorMessage(e);
                        actions.setStatus(message);
                    }
                    actions.setSubmitting(false);
                }}
                render={(formProps: FormikProps<EditLeagueFormValues>) => (
                    <Form>
                        <FieldSet>
                            {canElevatedEditLeague && (
                                <FormField description="League ID" disabled name="id" type="text" />
                            )}
                            <FormField name="name" description="League Name" type="text" />
                            <FormField
                                aria-describedby="How would you describe your league? This field allows markdown formatting."
                                component="textarea"
                                description="Description"
                                name="description"
                            />
                            <MarkdownPreview fieldName="Description" source={formProps.values.description} />
                            <FormField description="Email" name="email" type="email" />
                            <FormField description="Phone Number" name="phoneNumber" type="tel" />
                            <FormField description="Website URL" name="websiteUrl" type="url" />
                            <FormField description="Discord URL" name="discordUrl" type="url" />
                            <FormField
                                description="Organization Term (capitalized, singular)"
                                name="organizationTerm"
                                placeholder="How do you refer to your organizations?"
                                type="text"
                            />
                            <FormField
                                className="create-league__league"
                                component="select"
                                description="Timezone"
                                name="timezone"
                            >
                                <option key={-1} value={''} hidden disabled>
                                    Select timezone
                                </option>
                                {Core.Constants.SUPPORTED_TIMEZONES.map((tz: string, ix: number) => {
                                    return (
                                        <option key={ix} value={tz}>
                                            {tz}
                                        </option>
                                    );
                                })}
                            </FormField>
                            <FormField type="checkbox" name="enableChat" description="Enable built-in chat" />
                            {formProps.values.enableChat && (
                                <div className="ml5x">
                                    <FormField type="checkbox" name="disableDms" description="Disable DMs" />
                                    <div id="restrictChatUsers" className="league-settings-page__form__restrict-chat">
                                        <FormField
                                            type="checkbox"
                                            name="restrictChatUsers"
                                            description="Exclude Captains and Players from chat"
                                        />
                                        <ToolTip trigger={<FontAwesomeIcon icon={['fas', 'circle-info']} size="1x" />}>
                                            Only Coaches are auto-added to chat; organization managers may join.
                                        </ToolTip>
                                    </div>
                                </div>
                            )}
                            <FormField description="Disable user profile pictures" name="disablePfp" type="checkbox" />
                            <FormField
                                description="Require players to link their Discord account prior to competing"
                                name="requireUsersToLinkDiscord"
                                type="checkbox"
                            />
                            <FormField
                                description={`Restrict registration to a list of allowed email domains that each ${organizationTerm.toLowerCase()} must specify`}
                                name="restrictRegistrationToWhitelistedDomains"
                                type="checkbox"
                            />
                            <FormField
                                description="Truncate player last names for public users"
                                name="truncatePlayerNames"
                                type="checkbox"
                            />
                            <FormField
                                description="Allow anyone to create teams"
                                name="membersCanCreateTeams"
                                type="checkbox"
                            />
                            <FormField
                                description="Disable Discord competing status for all users"
                                name="disableDiscordCompetingStatus"
                                type="checkbox"
                            />
                            <ToolTip
                                hide={!isLite}
                                trigger={
                                    <FormField
                                        description="Require users to identify themselves with a verification photo"
                                        disabled={isLite}
                                        name="requireIdentityVerification"
                                        type="checkbox"
                                    />
                                }
                            >
                                <span>This action is not available in {Core.Constants.PLANS.LITE.NAME}.</span>
                            </ToolTip>
                            {formProps.values.requireIdentityVerification && (
                                <div className="ml5x">
                                    <FormField
                                        component="textarea"
                                        description="Identity verification instructions"
                                        name="identityVerificationInstructions"
                                        placeholder="What should users include in their verification photo? This field allows markdown formatting."
                                    />
                                    <MarkdownPreview
                                        fieldName="Identity verification instructions"
                                        source={formProps.values.identityVerificationInstructions}
                                    />
                                </div>
                            )}
                            <FormField
                                description="Hide this league from league search"
                                name="hideFromSearch"
                                type="checkbox"
                            />
                        </FieldSet>

                        {canElevatedEditLeague && (
                            <FieldSet className="mt4x" legend="Admin Settings">
                                <FormField description="Legal Entity" name="legalEntity" type="text" />
                                <FormField description="Parent League ID" name="parentLeagueId" type="text" />
                                <FormField description="Legal Terms URL" name="legalUrl" type="text" />
                                <FormField
                                    component="textarea"
                                    name="u13ConsentTerms"
                                    description="U13 Consent Terms"
                                />
                                <MarkdownPreview
                                    fieldName="U13 Consent Terms"
                                    source={formProps.values.u13ConsentTerms}
                                />
                                <FormField component="select" description="Feature Group" name="featureGroup">
                                    {Object.keys(Core.Models.FeatureGroup)
                                        .filter(
                                            (featureGroup: string) =>
                                                typeof Core.Models.FeatureGroup[featureGroup as any] === 'number'
                                        )
                                        .map((featureGroup: string) => ({
                                            key: +Core.Models.FeatureGroup[featureGroup as any],
                                            value: featureGroup,
                                        }))
                                        .map((featureGroup: { key: number; value: string }) => (
                                            <option key={featureGroup.key} value={featureGroup.key}>
                                                {featureGroup.value}
                                            </option>
                                        ))}
                                </FormField>
                                <div className="disp-flex">
                                    <FormField
                                        description="Do not enforce email verification"
                                        name="bypassEmailVerificationRestrictions"
                                        type="checkbox"
                                    />
                                    <Popover
                                        button={
                                            <IconButton as="button" buttonLabel="more info" buttonSize="small">
                                                <FontAwesomeIcon icon={['fas', 'circle-info']} />
                                            </IconButton>
                                        }
                                    >
                                        <div className="p">
                                            <p>
                                                New users will still receive an email to verify their email address, but
                                                their verification status will not be enforced in this league.
                                            </p>

                                            <p>
                                                Users under 13 will still require consent from their parents to
                                                participate.
                                            </p>
                                        </div>
                                    </Popover>
                                </div>

                                <FormField
                                    description="Allow users under 13 years old to register"
                                    name="allowU13"
                                    type="checkbox"
                                />
                                <FormField description="Billing" name="isBilling" type="checkbox" />
                                <FormField
                                    description="Enable custom registration fields"
                                    name="customRegistrationFieldsEnabled"
                                    type="checkbox"
                                />
                                <FormField
                                    description="Disable Discord notifications"
                                    name="disableDiscordNotifications"
                                    type="checkbox"
                                />
                                <FormField
                                    description="Disable organization invites"
                                    name="disableInvites"
                                    type="checkbox"
                                />
                                <FormField
                                    description="Enable payment features"
                                    name="enablePaymentFeatures"
                                    type="checkbox"
                                />
                                <FormField
                                    description="Enable season age restrictions"
                                    name="enableSeasonAgeRestrictions"
                                    type="checkbox"
                                />
                                <FormField
                                    className="disp-none"
                                    description="Enable social features"
                                    name="enableSocialFeatures"
                                    type="checkbox"
                                />
                            </FieldSet>
                        )}

                        {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                        <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                        <SolidButton
                            as="button"
                            className="full-width mt2x"
                            onClick={formProps.submitForm}
                            pending={formProps.isSubmitting}
                            type="submit"
                        >
                            Save
                        </SolidButton>
                    </Form>
                )}
            />
        </ContentContainer>
    );
};

export default BasicSettings;
