import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Core from '../../core';
import { ReactComponent as FreeAgentsIcon } from '../../assets/images/icon-dashboard-teams.svg';
import ChangeDesignationModal, {
    EditDesignationFormValues,
    EditDesignationsFormActions,
} from '../../components/changeDesignationModal';
import TeamDesignation from '../../components/teamDesignation';
import { useAlternateSeasonName, useLeagueConfiguration, useUserPermissionService } from '../../hooks/store';
import { TeamService } from '../../services/teamService';
import ConfirmModal from '../confirmModal';
import Loading from '../loading';
import Menu from '../menu';
import SetTeamSeason from '../setTeamSeason';
import TeamMembers from '../teamMembers';

import './index.scss';

export interface TeamListProps {
    teams: Core.Models.Team[];
    readonly?: boolean;
    hideTeamControls?: boolean;
    hideTeamDetails?: boolean;
    organizationId?: string;
    showGameName?: boolean;
    reloadTeamList?: () => Promise<void>;
}

const TeamList = (props: TeamListProps): JSX.Element => {
    const {
        teams: inputTeams,
        hideTeamControls,
        hideTeamDetails,
        organizationId,
        readonly,
        reloadTeamList,
        showGameName,
        ...rest
    } = props;

    const leagueConfiguration = useLeagueConfiguration();
    const [teamBeingDesignated, setTeamBeingDesignated] = useState<Core.Models.Team | undefined>(undefined);

    const [teams, setTeams] = useState<Core.Models.Team[]>(inputTeams);
    const [teamBeingDeleted, setTeamBeingDeleted] = useState<Core.Models.Team | undefined>(undefined);
    const userPermissionService = useUserPermissionService();

    const seasonAlternateName = useAlternateSeasonName();

    useEffect(() => setTeams(inputTeams), [inputTeams]);

    const deleteTeam = async () => {
        await TeamService.deleteTeam(teamBeingDeleted!.id);
        setTeamBeingDeleted(undefined);
        await reloadTeamList?.();
    };

    const [teamSettingOpenToFreeAgents, setTeamSettingOpenToFreeAgents] = useState<Core.Models.Team | undefined>(
        undefined
    );
    const setOpenToFreeAgents = useCallback(
        async (team: Core.Models.Team) => {
            setTeamSettingOpenToFreeAgents(team);
            try {
                await TeamService.setOpenToFreeAgents({ openToFreeAgents: !team.openToFreeAgents, teamId: team.id });
                setTeams([
                    ...teams.map((t: Core.Models.Team) => {
                        if (t.id === team.id) {
                            return { ...t, openToFreeAgents: !team.openToFreeAgents };
                        }
                        return t;
                    }),
                ]);
                toast.success('Successfully updated free agent preferences');
            } catch (e) {
                toast.error('Unable to update free agent preferences.  Please try again.');
            } finally {
                setTeamSettingOpenToFreeAgents(undefined);
            }
        },
        [teams]
    );

    const [teamEditingSeason, setTeamEditingSeason] = useState<Core.Models.Team | undefined>(undefined);
    const clearTeamEditingSeason = () => setTeamEditingSeason(undefined);

    return (
        <div className="team-list">
            {orderBy(teams, (team: Core.Models.Team) => team.name.toLowerCase()).map((team: Core.Models.Team) => {
                // if `readonly` is set, add/edit isn't allowed, even if you'd normally have rights (ie. on user profile)
                const canEditTeam =
                    !readonly && userPermissionService.hasTeamAccess(Core.Models.PermissionLevel.Edit, team.id);
                const hasElevatedEdit =
                    !readonly &&
                    userPermissionService.hasOrganizationAccess(
                        Core.Models.PermissionLevel.ElevatedEdit,
                        organizationId
                    );
                const currentSeason = team.currentSeason;
                const hasOutstandingPayables = team.members.some(
                    (member: Core.Models.TeamMember) =>
                        member.roleId !== Core.Models.TeamRoleId.Coach && member.hasOutstandingPayables
                );
                return (
                    <div
                        className={classNames('team-list__team', { 'team-list__team--has-menu': canEditTeam })}
                        key={team.id}
                    >
                        <div className="team-list__team__header">
                            <div className="team-list__designation">
                                <TeamDesignation
                                    designation={team.designation}
                                    radius={Core.Models.RadiusSizes.Medium}
                                    {...(canEditTeam && {
                                        menu: (
                                            <Menu className="team-list__menu">
                                                {!hasOutstandingPayables ? (
                                                    <button onClick={() => setTeamEditingSeason(team)}>
                                                        {team.currentSeason ? 'Change' : 'Join'} {seasonAlternateName}
                                                    </button>
                                                ) : (
                                                    <button disabled>
                                                        Cannot join {seasonAlternateName.toLowerCase()} - some members
                                                        have not paid fee
                                                    </button>
                                                )}
                                                {((!!currentSeason &&
                                                    currentSeason.season.currentState <=
                                                    Core.Models.CompetitionState.NotStarted &&
                                                    currentSeason.season.allowFreeAgents) ||
                                                    team.openToFreeAgents) && (
                                                        <button
                                                            disabled={!!teamSettingOpenToFreeAgents}
                                                            onClick={() => setOpenToFreeAgents(team)}
                                                        >
                                                            {team.openToFreeAgents
                                                                ? 'Decline Free Agents'
                                                                : 'Accept Free Agents'}
                                                        </button>
                                                    )}
                                                {canEditTeam && (
                                                    <button onClick={() => setTeamBeingDesignated(team)}>
                                                        Change Designation
                                                    </button>
                                                )}
                                                {hasElevatedEdit && (
                                                    <button onClick={() => setTeamBeingDeleted(team)}>
                                                        Delete
                                                    </button>
                                                )}
                                            </Menu>
                                        ),
                                    })}
                                />
                            </div>
                        </div>
                        <div className="team-list__team__content">
                            <Link to={`/teams/${team.id}`} className="team-list__team__name">
                                {team.name}
                                {teamSettingOpenToFreeAgents?.id === team.id && <Loading />}
                            </Link>
                            <div className="team-list__team__season">
                                <ParticipantSeason participantSeason={team.currentSeason} />
                            </div>
                            {((team.game && showGameName) || false) && ( // TODO replace false with guard for season info
                                <div className="team-list__subcontent">
                                    {/* TODO add season info {team.season && showSeason && <span className="team-list__team__game">{season}</span>} */}
                                    {team.game && showGameName && (
                                        <div className="team-list__team__game">{team.game.name}</div>
                                    )}
                                </div>
                            )}
                            {!hideTeamDetails && (
                                <TeamMembers team={team} canEdit={canEditTeam} canAdd={canEditTeam} {...rest} />
                            )}
                            {team.openToFreeAgents && (
                                <div
                                    className={classNames('team-list__team__free-agents-icon', {
                                        'team-list__team--has-details': hideTeamDetails ? false : canEditTeam,
                                    })}
                                >
                                    <FreeAgentsIcon title="Open to free agents" />
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
            {(!teams || teams.length <= 0) && <p>No teams yet</p>}
            {!!teamBeingDeleted && (
                <ConfirmModal
                    confirmText="Yes"
                    onCancel={() => setTeamBeingDeleted(undefined)}
                    onConfirm={deleteTeam}
                    title="Are you sure"
                >
                    <p>
                        Are you sure you want to delete <em>{teamBeingDeleted.name}</em>?
                    </p>
                </ConfirmModal>
            )}
            {!!teamBeingDesignated && (
                <ChangeDesignationModal
                    designations={leagueConfiguration?.designations}
                    onClose={() => setTeamBeingDesignated(undefined)}
                    onRemoved={async () => {
                        setTeamBeingDesignated(undefined);
                        await reloadTeamList?.();
                    }}
                    onSubmit={async (values: EditDesignationFormValues, actions: EditDesignationsFormActions) => {
                        try {
                            actions.setStatus(undefined);
                            await TeamService.editTeam({
                                id: teamBeingDesignated.id,
                                name: teamBeingDesignated.name,
                                designationId: values.designationId,
                            });
                            setTeamBeingDesignated(undefined);
                            await reloadTeamList?.();
                        } catch (error) {
                            const message = Core.API.getErrorMessage(error);
                            actions.setStatus(message);
                        } finally {
                            actions.setSubmitting(false);
                        }
                    }}
                    team={teamBeingDesignated}
                />
            )}
            {teamEditingSeason && (
                <SetTeamSeason
                    hasEntryFee={!!teamEditingSeason.currentSeason?.season.payable}
                    onAddFulfillment={(fulfillment: Core.Models.PayableFulfillment) => {
                        setTeams((currentTeams: Core.Models.Team[]) => {
                            const teamIndex = currentTeams.findIndex(
                                (team: Core.Models.Team) => team.id === fulfillment.teamId
                            );
                            if (teamIndex < 0) return currentTeams;

                            currentTeams[teamIndex].fulfillments.push(fulfillment);
                            return [...currentTeams];
                        });
                    }}
                    onCancel={clearTeamEditingSeason}
                    onComplete={async () => {
                        reloadTeamList && (await reloadTeamList());
                        clearTeamEditingSeason();
                    }}
                    seasonId={teamEditingSeason.currentSeason?.season.id}
                    team={teamEditingSeason}
                />
            )}
        </div>
    );
};

interface ParticipantSeasonProps {
    participantSeason?: Core.Models.ParticipantSeason;
}

const ParticipantSeason = (props: ParticipantSeasonProps) => {
    const { participantSeason } = props;
    if (!participantSeason || participantSeason.season.currentState === Core.Models.CompetitionState.IsComplete) {
        return <>Not in competition</>;
    }

    const { season, isParticipating } = participantSeason;

    if (season.currentState === Core.Models.CompetitionState.NotStarted && isParticipating) {
        return <>Will compete in {season.name}</>;
    }

    return (
        <>
            {season.name} {isParticipating ? '' : ' (not competing)'}
        </>
    );
};

export default TeamList;
