import React, { useEffect, useState } from 'react';
import { Formik, FormikProps, Form } from 'formik';
import indefinite from 'indefinite';
import { orderBy } from 'lodash';
import Select, { OptionsType, SelectOption } from 'react-select';
import * as Yup from 'yup';

import * as Core from '../../core';
import FormField from '../../components/formField';
import { useAlternateSeasonName, useHasLeagueAccess, useLeague, useOrganizationTerm } from '../../hooks/store';
import { OrganizationService } from '../../services/organizationService';
import { SolidButton } from '../buttons-visuals';
import InfoMessage from '../infoMessage';
import withLoading, { WithLoadingProps } from '../withLoading';

import './addTeamMemberForm.scss';

interface AddTeamMemberFormProps extends WithLoadingProps {
    canAddPlayers: boolean;
    enableSeasonAgeRestrictions: boolean;
    onSubmit: (members: Core.Models.OrganizationMember[], teamRoleId: string) => Promise<void>;
    team: Core.Models.Team;
    teamMemberLength: number;
}

export interface AddTeamMemberFormValues {
    teamRoleId: string;
    userEntityRoleIds: string[];
}

const schema = Yup.object().shape({
    teamRoleId: Yup.string().required('Team role is required'),
    userEntityRoleIds: Yup.array().of(Yup.string()).required('Users are required'),
});

const getMemberName = (member: Core.Models.OrganizationMember) => {
    if (!member.firstName) return member.email;

    const gamerHandle = !!member.gamerHandle ? ` (${member.gamerHandle})` : '';
    return Core.Identity.renderMemberName(member) + gamerHandle;
};

const AddTeamMemberForm = ({
    canAddPlayers,
    onSubmit,
    setError,
    setIsLoading,
    team,
    teamMemberLength,
}: AddTeamMemberFormProps) => {
    const [eligibleMembers, setEligibleMembers] = useState<Core.Models.OrganizationMember[]>([]);

    const league = useLeague();
    const seasonAlternateName = useAlternateSeasonName();
    const organizationTerm = useOrganizationTerm({ lowercase: true });
    const canEditLeague = useHasLeagueAccess(Core.Models.PermissionLevel.Edit);

    useEffect(() => {
        (async () => {
            try {
                const response = await OrganizationService.getEligibleMembersToAdd(team.organizationId, team.id);
                const filtered = response.filter((om: Core.Models.OrganizationMember) => om.email || om.firstName);
                const ordered = orderBy(filtered, [
                    (om: Core.Models.OrganizationMember) => om.firstName,
                    (om: Core.Models.OrganizationMember) => om.lastName,
                ]);
                setEligibleMembers(ordered);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <Formik
            initialValues={Object.assign({}, {
                teamRoleId: Core.Models.TeamRoleId.Player,
                userEntityRoleIds: [],
            } as AddTeamMemberFormValues)}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                actions.setStatus(undefined);
                try {
                    const members = eligibleMembers.filter((om: Core.Models.OrganizationMember) => {
                        return values.userEntityRoleIds.includes(om.userEntityRoleId);
                    });
                    await onSubmit(members, values.teamRoleId);
                } catch (e) {
                    const message = Core.API.getErrorMessage(e);
                    actions.setStatus(message);
                }
                actions.setSubmitting(false);
            }}
            render={(formProps: FormikProps<AddTeamMemberFormValues>) => {
                const participantLimitReached =
                    formProps.values.teamRoleId !== Core.Models.TeamRoleId.Coach && // coaches don't have a limit
                    !!team.game?.maximumSeats &&
                    teamMemberLength + formProps.values.userEntityRoleIds.length >= team.game?.maximumSeats;

                return (
                    <Form className="form">
                        <p className="add-team-member__info">
                            A member of {indefinite(organizationTerm)} can only be on one team per game.
                        </p>
                        {league?.enableSeasonAgeRestrictions && (
                            <p className="add-team-member__info">
                                Note: This league uses age restrictions on {seasonAlternateName.toLowerCase()}s. Adding
                                members above/below those restrictions may prevent you from joining some{' '}
                                {seasonAlternateName.toLowerCase()}s.
                            </p>
                        )}
                        <fieldset className="form-group">
                            <FormField component="select" name="teamRoleId" description="Role">
                                <option value={Core.Models.TeamRoleId.Player}>Player</option>
                                <option value={Core.Models.TeamRoleId.Coach}>Coach</option>
                            </FormField>
                        </fieldset>
                        {formProps.values.teamRoleId === Core.Models.TeamRoleId.Coach && (
                            <p className="add-team-member__warning">
                                Coaches have the same privileges as Captains except that they cannot directly
                                participate in events.
                            </p>
                        )}
                        {formProps.values.teamRoleId !== Core.Models.TeamRoleId.Coach &&
                            eligibleMembers.filter(
                                (user: Core.Models.OrganizationMember) =>
                                    !canEditLeague && !!user.hasOutstandingPayables
                            ).length > 0 && (
                                <p className="add-team-member__warning">
                                    Only members who have paid the league fee can be added to teams. Some members are
                                    not shown in the list below because they have not paid the fee.
                                </p>
                            )}
                        {eligibleMembers.length > 0 ? (
                            <>
                                {canAddPlayers || formProps.values.teamRoleId === Core.Models.TeamRoleId.Coach ? (
                                    <fieldset className="form-group">
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            isOptionDisabled={() => participantLimitReached}
                                            onChange={(selectedOptions: OptionsType<SelectOption>) => {
                                                formProps.setFieldValue(
                                                    'userEntityRoleIds',
                                                    selectedOptions.map((option: SelectOption) => option.value)
                                                );
                                            }}
                                            options={eligibleMembers
                                                .filter(
                                                    (user: Core.Models.OrganizationMember) =>
                                                        formProps.values.teamRoleId === Core.Models.TeamRoleId.Coach ||
                                                        canEditLeague ||
                                                        !user.hasOutstandingPayables
                                                )
                                                .map(
                                                    (user: Core.Models.OrganizationMember) =>
                                                        ({
                                                            label: getMemberName(user),
                                                            value: user.userEntityRoleId,
                                                        }) as SelectOption
                                                )}
                                            name="userEntityRoleIds"
                                            placeholder="Select a user"
                                            styles={{
                                                control: (provided: any) => ({
                                                    ...provided,
                                                    border: 'none',
                                                    minHeight: '56px',
                                                }),
                                                menu: (provided: any) => ({ ...provided, zIndex: 2 }),
                                            }}
                                        />
                                    </fieldset>
                                ) : (
                                    <InfoMessage
                                        message="This team is full. You can not add any more players."
                                        type="error"
                                    />
                                )}
                                {team?.currentSeason?.season &&
                                    team.currentSeason.season.currentState < Core.Models.CompetitionState.IsComplete &&
                                    formProps.values.teamRoleId === Core.Models.TeamRoleId.Player && (
                                        <p className="add-team-member__warning">
                                            Team is currently participating in {team.currentSeason.season.name}. Please
                                            double check that adding a team member does not violate your league's roster
                                            lock policy.
                                        </p>
                                    )}

                                {formProps.status && <InfoMessage message={formProps.status} type="error" />}
                                <InfoMessage filter={formProps.touched} message={formProps.errors} type="error" />

                                <fieldset className="form-group form-group--undecorated">
                                    <SolidButton
                                        as="button"
                                        layout="full"
                                        onClick={formProps.submitForm}
                                        pending={formProps.isSubmitting}
                                    >
                                        Add user to team
                                    </SolidButton>
                                </fieldset>
                            </>
                        ) : (
                            <InfoMessage message={'There are no remaining available team members.'} type="error" />
                        )}
                    </Form>
                );
            }}
        />
    );
};

export default withLoading(AddTeamMemberForm, { loadingProps: { blockItem: true } });
