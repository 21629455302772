import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';

import CookiesService from './cookiesService';
import { Configuration } from '../core/configuration';
import history from '../services/history';

const appInsights =
    Configuration.disableAnalytics || !process.env.REACT_APP_APPLICATION_INSIGHTS_KEY
        ? undefined
        : new ApplicationInsights({
              config: {
                  disableCookiesUsage: !CookiesService.getCookies()?.performance,
                  enableCorsCorrelation: true,
                  instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
              },
          });

if (appInsights) {
    appInsights.loadAppInsights();

    appInsights.context.application.build = Configuration.version;
    history.listen((_, action) => {
        if (action !== 'PUSH' && action !== 'REPLACE') {
            return;
        }

        // slight timeout to let the navigation happen first
        setTimeout(() => {
            ReactGA.send('pageview');
            appInsights.trackPageView({});
        }, 100);
    });
}

const event = (name: string, props?: any) => {
    ReactGA.event({ category: 'event', action: name });
    if (appInsights) {
        appInsights.trackEvent({ name }, props);
    }
};

const exception = (error: Error) => {
    if (appInsights) {
        appInsights.trackException({ error });
    }
};

const getAllCookies = (startsWith?: string) =>
    Object.entries(Cookies.get()).filter((entry) => !startsWith || entry[0].startsWith(startsWith));

const initializeGoogleAnalytics = (enabled: boolean) => {
    if (!!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        if (enabled) {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        } else {
            ReactGA.reset();
        }
    }
};

// initialize GA on initial load
if (!!CookiesService.getCookies()?.performance) {
    initializeGoogleAnalytics(true);
}

const login = (userId: string) => {
    ReactGA.event({ category: 'login', action: 'login' });

    if (!!appInsights) {
        appInsights.setAuthenticatedUserContext(userId);
    }
};

const logout = () => {
    ReactGA.event({ category: 'logout', action: 'logout' });

    if (!!appInsights) {
        appInsights.clearAuthenticatedUserContext();
    }
};

const removeCookie = (name: string, domain: string) => {
    console.log(`Removing '${name}' 🍪 from ${domain}`);
    Cookies.remove(name, { path: '/', domain });
};

const updatePerformanceCookiesPreference = (performanceCookiesEnabled: boolean) => {
    if (!!appInsights) {
        appInsights.core.getCookieMgr().setEnabled(!!performanceCookiesEnabled);
    }

    // turn on GA after accepting
    initializeGoogleAnalytics(performanceCookiesEnabled);

    if (!performanceCookiesEnabled) {
        // delete app insights cookies
        const appInsightsCookies = getAllCookies('ai_');
        for (const cookie of appInsightsCookies) {
            removeCookie(cookie[0], window.location.hostname);
        }

        // delete GA cookies
        const gaCookies = getAllCookies('_ga');
        const hostname = window.location.hostname === 'localhost' ? 'localhost' : '.leaguespot.gg'; // just how GA does it
        for (const cookie of gaCookies) {
            removeCookie(cookie[0], hostname);
        }
    }
};

const AnalyticsService = {
    event,
    exception,
    login,
    logout,
    updatePerformanceCookiesPreference,
};

export default AnalyticsService;
