import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import * as Core from '../../core';
import CookieSettingsModal from './cookieSettings';
import { useCookies } from '../../hooks/store';
import CookiesService from '../../services/cookiesService';
import { cookiesSuccessAction } from '../../store/cookies/actions';
import { CookiePreferences } from '../../store/cookies/types';
import { Button } from '../button';
import { IconButton } from '../buttons-visuals';

import './index.scss';

const AcceptCookies = (): JSX.Element => {
    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

    const cookies = useCookies();
    const dispatch = useDispatch();

    useEffect(() => setShowPrompt(!cookies?.lastUpdatedUtc), [cookies?.lastUpdatedUtc]);

    const setCookies = useCallback(
        (preferences: CookiePreferences) => {
            const config = { ...preferences, lastUpdatedUtc: new Date().getTime() };
            dispatch(cookiesSuccessAction(config));
            CookiesService.setCookies(config);
        },
        [dispatch]
    );

    return (
        <>
            {showPrompt ? (
                <div className="accept-cookies" role="alert">
                    <p className="accept-cookies__header">
                        <span>Your privacy</span>
                        <FontAwesomeIcon icon={['fas', 'cookie']} size="sm" className="ml" />
                    </p>
                    <p className="accept-cookies__body">
                        By clicking "Accept All," you agree we can store cookies on your device in accordance with our{' '}
                        <a href={Core.Constants.COOKIE_POLICY_URL} rel="noopener noreferrer" target="_blank">
                            Cookie Policy
                        </a>
                        .
                    </p>
                    <div className="accept-cookies__button-section">
                        <Button
                            className="accept-cookies__button"
                            onClick={() => setCookies({ performance: true, strictlyNecessary: true })}
                            styleType={Core.Models.StyleType.Primary}
                        >
                            Accept all
                        </Button>
                        <Button
                            className="accept-cookies__button"
                            onClick={() => setCookies({ performance: false, strictlyNecessary: true })}
                            styleType={Core.Models.StyleType.Primary}
                        >
                            Necessary only
                        </Button>
                    </div>
                    <div className="accept-cookies__button-section">
                        <Button
                            className="accept-cookies__button"
                            onClick={() => setShowSettingsModal(true)}
                            styleType={Core.Models.StyleType.Primary}
                            outline
                        >
                            Customize settings
                        </Button>
                    </div>
                </div>
            ) : (
                <IconButton
                    as="button"
                    buttonSize="medium"
                    buttonLabel="Open cookie settings"
                    className="accept-cookies-closed"
                    onClick={() => setShowSettingsModal(true)}
                >
                    <FontAwesomeIcon icon={['fas', 'cookie']} size="xl" className="accept-cookies-closed__fa-icon" />
                </IconButton>
            )}
            {!!showSettingsModal && (
                <CookieSettingsModal onClose={() => setShowSettingsModal(false)} setCookies={setCookies} />
            )}
        </>
    );
};

export default AcceptCookies;
