import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { toast } from 'react-toastify';

import * as Core from '../../../core';
import { Select } from '../../../components/inputs';
import PaymentGatewayService from '../../../services/paymentGatewayService';
import Modal from '../../modal';
import ReviewPayable from '../reviewPayable';

const FulfillPayableModal = ({
    fulfillments,
    leagueDesignationId,
    onCompleted,
    payableEntityType,
    payableId,
    reloadData,
    setFulfillments,
    teamId,
    userId,
}: {
    fulfillments: Core.Models.PayableFulfillment[];
    leagueDesignationId?: string;
    onCompleted: () => void;
    payableEntityType: Core.Models.PayableEntityType;
    payableId?: string;
    reloadData: (showLoading: boolean) => Promise<void>;
    setFulfillments?: Dispatch<SetStateAction<Core.Models.PayableFulfillment[]>>;
    teamId?: string;
    userId?: string;
}) => {
    const [payableIdToPay, setPayableIdToPay] = useState<string>(payableId ?? '');
    const [payables, setPayables] = useState<Core.Models.Payable[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const payablesData = await PaymentGatewayService.getPayables(payableEntityType);
                setPayables(payablesData);
            } catch {
                toast.error('There was an issue retrieving entry fees');
            }
        })();
    }, []);

    const getFeeForPayable = (payable: Core.Models.Payable) => {
        if (Core.Utils.isDiscounted(payable)) return payable.discountFee!;
        return payable.fee;
    }

    return (
        <Modal onClose={onCompleted} title="Pay entry fee">
            <Select
                id="payableId"
                label="Choose Entry Fee"
                name="payableId"
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const payableId = event.target.value;
                    setPayableIdToPay(payableId);
                }}
                value={payableIdToPay}
            >
                <option value="" hidden disabled>
                    --
                </option>
                {orderBy(payables, (payable: Core.Models.Payable) => payable.name).map(
                    (payable: Core.Models.Payable) => (
                        <option key={payable.id} value={payable.id}>
                            {payable.name} - {Core.Currency.convertNumberToCurrency(getFeeForPayable(payable))}
                        </option>
                    )
                )}
            </Select>

            {payables.length > 0 && payableIdToPay && (
                <ReviewPayable
                    canBypassPayable={
                        !!payables
                            .find((payable: Core.Models.Payable) => payable.id === payableIdToPay)
                            ?.exemptLeagueDesignations?.some(
                                (ld: Core.Models.LeagueDesignation) => ld.id === leagueDesignationId
                            )
                    }
                    hasFulfilledPayable={
                        !!fulfillments.find(
                            (fulfillment: Core.Models.PayableFulfillment) =>
                                fulfillment.payableId === payableIdToPay &&
                                fulfillment.complete &&
                                !fulfillment.refunded &&
                                fulfillment.userId === userId
                        )
                    }
                    onAddFulfillment={async (fulfillment: Core.Models.PayableFulfillment) => {
                        setFulfillments?.((currentFulfillments: Core.Models.PayableFulfillment[]) => [
                            ...currentFulfillments,
                            fulfillment,
                        ]);
                        await reloadData(false);
                    }}
                    payable={payables.find((payable) => payable.id === payableIdToPay)!}
                    teamId={teamId}
                    userId={userId}
                />
            )}
        </Modal>
    );
};

export default FulfillPayableModal;
